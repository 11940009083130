import cloneDeep from 'lodash-es/cloneDeep'
import { TEXT_FILTER } from '@/constants/filterSearchType'

const initState = {
  search: '',
  items: [],
  reviews: [],
  offers: [],
  talents: [],
  status: false,
  filterType: {
    id: 'talentText',
    label: '名前',
  },
  filterItems: [
    {
      id: 'talentText',
      label: 'タレント名',
      type: TEXT_FILTER,
    },
  ],
  searchFilterItems: [],
  header: [
    {
      text: 'Actions',
      value: 'isActive',
      sortable: false,
    },
    {
      text: 'タレント名',
      value: 'talentText',
      sortable: true,
    },
    {
      text: '宛名',
      value: 'toName',
      sortable: false,
    },
    {
      text: 'レビュー',
      value: 'comment',
      sortable: false,
    },
    {
      text: 'ステータス',
      value: 'isActive',
      sortable: false,
    },
    {
      text: '投稿日',
      value: 'createdAtText',
      sortable: true,
    },
  ],
}

export const defaultState = () => cloneDeep(initState)
