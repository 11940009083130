<template>
  <v-navigation-drawer
    id="appDrawer"
    v-model="drawer"
    :mini-variant.sync="mini"
    :dark="$vuetify.dark"
    fixed
    app
    width="200"
  >
    <v-toolbar color="white" dark>
      <img src="https://firebasestorage.googleapis.com/v0/b/relaido-fanglee.appspot.com/o/assets%2Fimages%2Flogo.png?alt=media" height="50" alt="fanglee">
    </v-toolbar>
    <v-list dense expand>
      <template v-for="(item, i) in menu">
        <!--group with subitems-->
        <v-list-group
          v-if="item.items"
          :key="item.name"
          :prepend-icon="item.icon"
          no-action
        >
          <v-list-tile slot="activator" ripple="ripple">
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <template v-for="(subItem, j) in item.items">
            <!--sub group-->
            <v-list-group
              v-if="subItem.items"
              :key="subItem.name"
              :group="subItem.group"
              sub-group="sub-group"
            >
              <v-list-tile slot="activator" ripple="ripple">
                <v-list-tile-content>
                  <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                v-for="(grand, k) in subItem.children"
                :key="k"
                :to="grand.href ? grand.href : null"
                ripple="ripple"
              >
                <v-list-tile-content>
                  <v-list-tile-title>{{ grand.title }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list-group>
            <!--child item-->
            <v-list-tile
              v-else
              :key="j"
              :to="subItem.href ? subItem.href : null"
              :disabled="subItem.disabled"
              :target="subItem.target"
              ripple="ripple"
            >
              <v-list-tile-content>
                <v-list-tile-title>
                  <span>{{ subItem.title }}</span>
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action v-if="subItem.action">
                <v-icon :class="[subItem.actionClass || 'success--text']">
                  {{
                    subItem.action
                  }}
                </v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </template>
        </v-list-group>
        <v-subheader v-else-if="item.header" :key="i">
          {{
            item.header
          }}
        </v-subheader>
        <v-divider v-else-if="item.divider" :key="i" />
        <!--top-level link-->
        <v-list-tile
          v-else
          :key="item.name"
          :to="item.href ? item.href : null"
          :disabled="item.disabled"
          :target="item.target"
          ripple="ripple"
          rel="noopener"
        >
          <v-list-tile-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action v-if="item.subAction">
            <v-icon class="success--text">
              {{ item.subAction }}
            </v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex'
import { NS_APP_DRAWER } from '@/modules/store/_core/ns'

export default {
  name: 'AppDrawer',
  middleware: 'auth',
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(NS_APP_DRAWER, ['menu', 'mini', 'scrollSettings']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('drawer', val)
      },
    },
    computeGroupActive() {
      return true
    },
    sideToolbarColor() {
      return this.$vuetify.options.extra.sideNav
    },
  },
  methods: {
    genChildTarget(item, subItem) {
      if (subItem.href) return
      if (subItem.component) {
        return {
          name: subItem.component,
        }
      }
      return { name: `${item.group}/${subItem.name}` }
    },
  },
}
</script>

<style lang="stylus">
#appDrawer
  overflow: hidden
  .drawer-menu--scroll
    height: calc(100vh - 48px)
    overflow: auto
</style>
