import { db, firebase } from '@/plugins/firebase'
import { NS_LOGIN, NS_APP_DRAWER } from '@/modules/store/_core/ns'
import { admin, operator, agency } from '@/constants/role'

const userRef = db.collection('user')

const adminMenu = [
  { header: 'MENU' },
  {
    title: 'ダッシュボード',
    group: 'admin',
    icon: 'dashboard',
    name: 'Dashboard',
    href: '/dashboard',
  },
  {
    title: 'タレント',
    group: 'admin',
    icon: 'face',
    name: 'Talent',
    href: '/talent',
  },
  {
    title: 'オファー',
    group: 'admin',
    icon: 'local_offer',
    name: 'Offer',
    href: '/offer',
  },
  {
    title: 'レビュー',
    group: 'admin',
    icon: 'comment',
    name: 'review',
    href: '/review',
  },
  {
    title: '事務所',
    group: 'admin',
    icon: 'store_mall_directory',
    name: 'Agency',
    href: '/agency',
  },
  {
    title: '紹介者',
    group: 'admin',
    icon: 'how_to_reg',
    name: 'introducer',
    href: '/introducer',
  },
  {
    title: 'ユーザー',
    group: 'admin',
    icon: 'person',
    name: 'user',
    href: '/user',
  },
  {
    title: 'Web',
    icon: 'web',
    items: [
      {
        title: 'ピックアップ',
        group: 'admin',
        name: 'pickup',
        href: '/web/pickup',
      },
      {
        title: 'お知らせ',
        group: 'admin',
        name: 'news',
        href: '/web/news',
      },
    ],
  },
  {
    title: 'チャリティー',
    group: 'admin',
    icon: 'emoji_people',
    name: 'charity',
    href: '/charity',
  },
]

const opeMenu = [
  { header: 'MENU' },
  {
    title: 'ダッシュボード',
    group: 'admin',
    icon: 'dashboard',
    name: 'Dashboard',
    href: '/dashboard',
  },
  {
    title: 'タレント',
    group: 'admin',
    icon: 'face',
    name: 'Talent',
    href: '/talent',
  },
  {
    title: 'オファー',
    group: 'admin',
    icon: 'local_offer',
    name: 'Offer',
    href: '/offer',
  },
  {
    title: 'レビュー',
    group: 'admin',
    icon: 'comment',
    name: 'review',
    href: '/review',
  },
  {
    title: '事務所',
    group: 'admin',
    icon: 'store_mall_directory',
    name: 'Agency',
    href: '/agency',
  },
  {
    title: 'Web',
    icon: 'web',
    items: [
      {
        title: 'ピックアップ',
        group: 'admin',
        name: 'pickup',
        href: '/web/pickup',
      },
      {
        title: 'お知らせ',
        group: 'admin',
        name: 'news',
        href: '/web/news',
      },
    ],
  },
  {
    title: 'チャリティー',
    group: 'admin',
    icon: 'emoji_people',
    name: 'charity',
    href: '/charity',
  },
]

const agencyMenu = [
  { header: 'MENU' },
  {
    title: 'タレント',
    group: 'admin',
    icon: 'face',
    name: 'Talent',
    href: '/talent',
  },
  {
    title: 'オファー',
    group: 'admin',
    icon: 'local_offer',
    name: 'Offer',
    href: '/offer',
  },
]

export default function({ store, redirect, route }) {
  // ユーザー認証
  isLogin().then(user => {
    let currentUser = {}
    if (user) {
      const id = user.uid
      userRef
        .where('uid', '==', id)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(async function(doc) {
            currentUser = doc.data()
            await store.commit(`${NS_LOGIN}/onAuthStateChanged`, currentUser, {
              root: true,
            })
            await store.commit(`${NS_LOGIN}/onUserStatusChanged`, true, {
              root: true,
            })
            // メニューの出し分け
            if (currentUser.roleId === admin) {
              await adminMenu.forEach(item => {
                if (item.items) {
                  item.items.sort((x, y) => {
                    const textA = x.title.toUpperCase()
                    const textB = y.title.toUpperCase()
                    return textA < textB ? -1 : textA > textB ? 1 : 0
                  })
                }
              })
              store.commit(`${NS_APP_DRAWER}/setMenu`, adminMenu, {
                root: true,
              })
            } else if (currentUser.roleId === operator) {
              await opeMenu.forEach(item => {
                if (item.items) {
                  item.items.sort((x, y) => {
                    const textA = x.title.toUpperCase()
                    const textB = y.title.toUpperCase()
                    return textA < textB ? -1 : textA > textB ? 1 : 0
                  })
                }
              })
              store.commit(`${NS_APP_DRAWER}/setMenu`, opeMenu, {
                root: true,
              })
            } else if (currentUser.roleId === agency) {
              await agencyMenu.forEach(item => {
                if (item.items) {
                  item.items.sort((x, y) => {
                    const textA = x.title.toUpperCase()
                    const textB = y.title.toUpperCase()
                    return textA < textB ? -1 : textA > textB ? 1 : 0
                  })
                }
              })
              store.commit(`${NS_APP_DRAWER}/setMenu`, agencyMenu, {
                root: true,
              })
              if (
                route.name.startsWith('user') ||
                route.name.startsWith('agency')
              ) {
                redirect('/talent')
              }
            }
          })
        })
    } else {
      store.commit(`${NS_LOGIN}/onAuthStateChanged`, {}, { root: true })
      redirect('/login')
    }
  })
}

function isLogin() {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      resolve(user)
    })
  })
}
