import { db, firebase } from '@/plugins/firebase'
import 'firebase/auth'

const userRef = db.collection('user')
export const actions = {
  login: ({ state, commit }) => {
    const email = state.form.emailAddress
    const pass = state.form.pass
    firebase
      .auth()
      .signInWithEmailAndPassword(email, pass)
      .then(res => {
        if (res.operationType === 'signIn') {
          const id = firebase.auth().currentUser.uid
          userRef
            .where('uid', '==', id)
            .get()
            .then(function(querySnapshot) {
              querySnapshot.forEach(function(doc) {
                const user = doc.data()
                commit('onAuthStateChanged', user)
              })
            })
        }
        commit('success')
      })
      .catch(function(error) {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // [START_EXCLUDE]
        if (errorCode === 'auth/wrong-password') {
          alert('Wrong password.')
        } else {
          alert(errorMessage)
        }
        console.log(error)
      })
  },
}
