export const getters = {
  removeId(state) {
    return state.removeId
  },
  search(state) {
    return state.search
  },
  filterType(state) {
    return state.filterType
  },
  filterItems(state) {
    return state.filterItems
  },
  header(state) {
    return state.header
  },
  tableItems(state) {
    const { search, items, filterType } = state
    const s = search ? search.toLowerCase() : ''
    return s
      ? items.filter(item => {
          const v = item[filterType.id]
          return v ? v.toLowerCase().includes(s) : false
        })
      : items
  },
}
