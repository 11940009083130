import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  search: '',
  items: [],
  filterType: {
    id: 'name',
    label: '名前',
  },
  filterItems: [
    {
      id: 'introducerId',
      label: '紹介者ID',
    },
    {
      id: 'expire',
      label: '紹介料発生終了日',
    },
  ],
  header: [
    {
      text: '操作',
      width: '50px',
      align: 'centor',
      value: 'actions',
      sortable: false,
    },
    {
      text: '名前',
      value: 'name',
      sortable: true,
    },
    {
      text: 'ID',
      value: 'id',
      sortable: true,
    },
    {
      text: '紹介料率',
      value: 'margin',
      sortable: true,
    },
    {
      text: 'Create Date',
      value: 'create_date',
      sortable: true,
    },
    {
      text: '紹介料発生終了日',
      value: 'expire',
      sortable: false,
    },
  ],
  removeId: '',
}

export const defaultState = () => cloneDeep(initState)
