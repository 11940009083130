import { defaultState } from './_state'
import { convertDateToText } from '@/util/data_util'
import { admin } from '@/constants/role'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  setIdForRemove(state, payload) {
    state.removeId = payload
  },
  updateFilterType(state, payload) {
    state.filterType = payload
  },
  updateSearchValue(state, payload) {
    state.search = payload
  },
  changeHeaderByRole(state, roleId) {
    if (admin !== roleId) {
      // 意図的に空配列を渡さないと、headerの読み込みでエラーになるのでsplice禁止
      console.log(state.header)
      delete state.header[6]
    }
  },
  convertText(state) {
    const { items, roles } = state
    state.items = items.map(item => {
      const targetRole = roles.find(role => role.id === item.roleId)
      item.roleText = targetRole ? targetRole.name : ''
      item.createdAtText = item.createdAt
        ? convertDateToText(item.createdAt.toDate())
        : ''
      item.updatedAtText = item.updatedAt
        ? convertDateToText(item.updatedAt.toDate())
        : ''
      return item
    })
  },
  updateFilter(state, payload) {
    console.log('[mutaiton] updateFilter : ', payload)
    state.searchFilterItems = payload
  },
}
