import cloneDeep from 'lodash-es/cloneDeep'
import {
  TEXT_FILTER,
  AGENCY_FILTER,
  DATE_FILTER,
  ROLE_FILTER,
} from '@/constants/filterSearchType'

const initState = {
  search: '',
  items: [],
  agencies: [],
  roles: [],
  filterType: {
    id: 'name',
    label: '名前',
  },
  filterItems: [
    {
      id: 'name',
      label: '名前',
      type: TEXT_FILTER,
    },
    {
      id: 'emailAddress',
      label: 'メールアドレス',
      type: TEXT_FILTER,
    },
    {
      id: 'agencyText',
      label: '事務所',
      type: AGENCY_FILTER,
    },
    {
      id: 'roleText',
      label: 'タイプ',
      type: ROLE_FILTER,
    },
    {
      id: 'createdAtText',
      label: '作成日時',
      type: DATE_FILTER,
    },
    {
      id: 'updatedAtText',
      label: '更新日時',
      type: DATE_FILTER,
    },
  ],
  searchFilterItems: [],
  header: [
    {
      text: '操作',
      width: '50px',
      align: 'centor',
      value: 'actions',
      sortable: false,
    },
    {
      text: '名前',
      value: 'name',
      sortable: true,
    },
    {
      text: 'メールアドレス',
      value: 'emailAddress',
      sortable: false,
    },
    {
      text: '事務所',
      value: 'agencyText',
      sortable: false,
    },
    {
      text: 'タイプ',
      value: 'roleText',
      sortable: false,
    },
    {
      text: '作成日時',
      value: 'createdAtText',
      width: '180px',
      sortable: true,
    },
    {
      text: '更新日時',
      value: 'updatedAtText',
      width: '180px',
      sortable: true,
    },
  ],
  removeId: '',
}

export const defaultState = () => cloneDeep(initState)
