import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import Vue from 'vue'
import Vuex from 'vuex'
const serviceAccount =
  process.env.NODE_ENV !== 'production'
    ? require('@/config/.env.dev.json')
    : require('@/config/.env.prod.json')

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'
Vue.config.debug = debug
firebase.initializeApp({ ...serviceAccount })
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
const db = firebase.firestore()

export { db, firebase }
