export const getters = {
  form(state) {
    return state.form
  },
  select(state) {
    return state.select
  },
  logoPath(state) {
    const { form } = state
    if (form.logo) {
      return form.logo
    } else if (form.logoInfo.image) {
      return form.logoInfo.image
    } else {
      return ''
    }
  },
}
