import { firebaseAction } from 'vuexfire'
import { db } from '@/plugins/firebase'

const talentRef = db.collection('talent')
const webRef = db.collection('web')
const pickUpDoc = webRef.doc('pickup')

export const actions = {
  initStore: firebaseAction(async ({ bindFirebaseRef, commit }, payload) => {
    await bindFirebaseRef('webRef', webRef)
    await bindFirebaseRef('talentRef', talentRef)
    await bindFirebaseRef('pickupDocRef', pickUpDoc)
  }),
  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    try {
      unbindFirebaseRef('webRef')
      unbindFirebaseRef('talentRef')
      unbindFirebaseRef('pickupDocRef')
      unbindFirebaseRef('newsList')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),
  pickupStore: firebaseAction(({ bindFirebaseRef }, payload) => {
    pickUpDoc.set(payload)
  }),
}
