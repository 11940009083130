import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  form: {
    name: '',
    introducerId: '',
    margin: '',
    expire: '',
    address: '',
    emailAddress: '',
    phoneNumber: '',
    bank: {
      bankAccountHolder: '',
      bankBranchName: '',
      bankType: '',
      bankName: '',
      bankNumber: '',
    },
  },
  select: {
    bankTypeSelect: [
      { text: '普通', id: 1 },
      { text: '当座', id: 2 },
      { text: '貯蓄', id: 3 },
    ],
  },
}

export const defaultState = () => cloneDeep(initState)
