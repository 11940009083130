// 確認、完了ページ
const NS_CONFIRM_DIALOG = 'confirmDialog'
const NS_COMPLETE_DIALOG = 'completeDialog'
const NS_TABLE_FILTER = 'tableFilter'
const NS_ERROR_DIALOG = 'errorDialog'
const NS_LOGIN = 'logIn'

// ダッシュボードページ
const NS_DASHBOARD = 'dashboard'

// オファーページ
const NS_OFFER_LIST = 'offerList'
const NS_OFFER_EDIT = 'offerEdit'

// タレントページ
const NS_TALENT_LIST = 'talentList'
const NS_TALENT_EDIT = 'talentEdit'
const NS_TALENT_CREATE = 'talentCreate'

// ユーザーページ
const NS_USER_LIST = 'userList'
const NS_USER_EDIT = 'userEdit'
const NS_USER_CREATE = 'userCreate'

// 事務所ページ
const NS_AGENCY_LIST = 'agencyList'
const NS_AGENCY_EDIT = 'agencyEdit'
const NS_AGENCY_CREATE = 'agencyCreate'

const NS_REVIEW_LIST = 'reviewList'
const NS_APP_DRAWER = 'AppDrawer'

// 紹介者ページ
const NS_INTRODUCER_LIST = 'introducerList'
const NS_INTRODUCER_EDIT = 'introducerEdit'
const NS_INTRODUCER_CREATE = 'introducerCreate'

// チャリティーページ
const NS_CHARITY_LIST = 'charityList'
const NS_CHARITY_EDIT = 'charityEdit'
const NS_CHARITY_CREATE = 'charityCreate'

// タレントリクエスト
const NS_TALENT_REQUEST_LIST = 'talentRequestList'

// WEBサイト
const NS_WEB = 'web'
const NS_WEB_NEWS_EDIT = 'webNewsEdit'
const NS_WEB_NEWS_LIST = 'webNewsList'
const NS_WEB_NEWS_CREATE = 'webNewsCreate'

export {
  NS_CONFIRM_DIALOG,
  NS_COMPLETE_DIALOG,
  NS_TABLE_FILTER,
  NS_ERROR_DIALOG,
  NS_LOGIN,
  NS_DASHBOARD,
  NS_OFFER_LIST,
  NS_OFFER_EDIT,
  NS_TALENT_LIST,
  NS_TALENT_EDIT,
  NS_TALENT_CREATE,
  NS_USER_LIST,
  NS_USER_EDIT,
  NS_USER_CREATE,
  NS_AGENCY_LIST,
  NS_AGENCY_EDIT,
  NS_AGENCY_CREATE,
  NS_REVIEW_LIST,
  NS_APP_DRAWER,
  NS_INTRODUCER_LIST,
  NS_INTRODUCER_EDIT,
  NS_INTRODUCER_CREATE,
  NS_WEB,
  NS_WEB_NEWS_EDIT,
  NS_WEB_NEWS_LIST,
  NS_WEB_NEWS_CREATE,
  NS_CHARITY_LIST,
  NS_CHARITY_EDIT,
  NS_CHARITY_CREATE,
  NS_TALENT_REQUEST_LIST,
}
