import { actions } from './_actions'
import { getters } from './_getters'
import { mutations } from './_mutations'
import { defaultState } from '@/modules/store/agency/list/_state'
export const agencyListStore = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: defaultState,
}
