import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG, NS_ERROR_DIALOG } from '@/modules/store/_core/ns'

const agencyRef = db.collection('agency')
const roleRef = db.collection('role')
const userRef = db.collection('user')

const functions = firebase.functions()
const createAdminister = functions.httpsCallable('createAdminister')

export const actions = {
  // todo: firebase の action に差替え
  // see: https://qiita.com/uriuriuriu/items/2c8b86a77891a9f29295
  bindRef: firebaseAction(async ({ bindFirebaseRef, commit }) => {
    console.log('[actions] BindRef :')
    await bindFirebaseRef('select.agencies', agencyRef)
    await bindFirebaseRef('select.roles', roleRef)
    commit('updateSelectedAgency')
    commit('updateSelectedRole')
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef }) => {
    try {
      console.log('[actions] unBindRef :')
      unbindFirebaseRef('select.agencies')
      unbindFirebaseRef('select.roles')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),

  submit({ commit, state }) {
    const source = {
      emailAddress: state.form.emailAddress,
      pass: state.form.newPass,
      role: state.form.roleId,
    }
    createAdminister(source)
      .then(res => {
        if (res.data.uid && res.data.status === 200) {
          userRef
            .doc(res.data.uid)
            .set(makePost(state.form, res.data.uid, state.isAgency))
          commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, {
            root: true,
          })
        } else if (res.data.status === 400) {
          commit(
            `${NS_ERROR_DIALOG}/showDialog`,
            'すでに使われているメールアドレスです。',
            {
              root: true,
            }
          )
        } else {
          commit(
            `${NS_ERROR_DIALOG}/showDialog`,
            'ユーザーの作成に失敗しました。もう一度お試しください。',
            {
              root: true,
            }
          )
        }
      })
      .catch(() => {
        commit(
          `${NS_ERROR_DIALOG}/showDialog`,
          'ユーザーの作成に失敗しました。もう一度お試しください。',
          {
            root: true,
          }
        )
      })
  },
}

function makePost(form, id, isAgency) {
  const post = {}
  const stamp = firebase.firestore.FieldValue.serverTimestamp()
  post.name = form.name
  post.emailAddress = form.emailAddress
  if (isAgency) {
    post.agencyId = form.agencyId
  }
  post.roleId = form.roleId
  post.uid = id
  // firestore用の現在時刻取得
  post.createdAt = stamp
  post.updatedAt = stamp
  return post
}
