import { convertDateToText } from '@/util/data_util'
import { defaultState } from '@/modules/store/offer/edit/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  convertText(state) {
    const { form, talent, agencies, convertItems } = state
    const targetAgency = agencies.find(agency => agency.id === form.agencyId)
    convertItems.agencyName = targetAgency ? targetAgency.name : ''
    convertItems.talentName = talent ? talent.name.name : ''
    convertItems.createdAtText = form.createdAt
      ? convertDateToText(form.createdAt.toDate())
      : ''
    convertItems.deliveredAtText = form.deliveredAt
      ? convertDateToText(form.deliveredAt.toDate())
      : ''
  },
  updateSelectedStatus(state, payload) {
    console.log('[mutations] updateSelectedStatus :')
    const { select, form } = state
    const status = payload.id ? payload.id : form.status
    if (payload.role == '3') {
      const reStatues = state.select.statuses.filter(function(item) {
        return item.id != '5' && item.id != '6'
      })
      state.select.statuses = reStatues
    }
    if (payload.role == '1' || payload.role == '2') {
      const statuspop = state.select.statuses.pop()
      state.select.statuses.splice(4, 0, statuspop)
    }

    state.selectedStatus = select.statuses.find(c => c.id === status)
    form.status = state.selectedStatus ? state.selectedStatus.id : null
  },
  mergeForm(state) {
    const { form, remote } = state
    console.log('[mutation] mergeForm : ', remote)
    state.form = {
      ...form,
      ...remote,
    }
    // note: id はマージされないため個別対応
    state.form.id = remote.id
  },
}
