import { defaultState } from '@/modules/store/common/confirm-dialog/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  showDialog(state) {
    console.log('[mutations] showDialog :')
    state.visible = true
  },
  close(state) {
    console.log('[mutations] close :')
    state.visible = false
  },
}
