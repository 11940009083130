import cloneDeep from 'lodash-es/cloneDeep'
import { TEXT_FILTER, DATE_FILTER } from '@/constants/filterSearchType'

const initState = {
  search: '',
  items: [],
  roles: [],
  filterType: {
    id: 'title',
    label: 'タイトル',
  },
  filterItems: [
    {
      id: 'title',
      label: 'タイトル',
      type: TEXT_FILTER,
    },
    {
      id: 'createdAtText',
      label: '作成日時',
      type: DATE_FILTER,
    },
    {
      id: 'updatedAtText',
      label: '更新日時',
      type: DATE_FILTER,
    },
  ],
  searchFilterItems: [],
  header: [
    {
      text: '操作',
      width: '50px',
      align: 'centor',
      value: 'actions',
      sortable: false,
    },
    {
      text: 'タイトル',
      value: 'title',
      sortable: true,
    },
    {
      text: '作成日時',
      value: 'createdAtText',
      width: '180px',
      sortable: true,
    },
    {
      text: '更新日時',
      value: 'updatedAtText',
      width: '180px',
      sortable: true,
    },
  ],
  removeId: '',
}

export const defaultState = () => cloneDeep(initState)
