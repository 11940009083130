import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { agency } from '@/constants/role'

const agencyRef = db.collection('agency')
const statusRef = db.collection('offer_status')
const talentRef = db.collection('talent')
const videoRef = db.collection('video')

export const actions = {
  bindRef: firebaseAction(async ({ bindFirebaseRef, state, commit }) => {
    console.log('[actions] BindRef :')
    await firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection('user')
          .where('uid', '==', user.uid)
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(async function(doc) {
              const currentUser = doc.data()
              let offerRef = {}
              if (currentUser) {
                if (currentUser.roleId === agency) {
                  offerRef = db
                    .collection('offer')
                    .where('agencyId', '==', currentUser.agencyId)
                } else {
                  offerRef = db.collection('offer')
                }
                await bindFirebaseRef('statuses', statusRef)
                await bindFirebaseRef('agencies', agencyRef)
                await bindFirebaseRef('talents', talentRef)
                await bindFirebaseRef('items', offerRef)

                state.items.forEach(async offer => {
                  await videoRef
                    .where('offerId', '==', offer.id)
                    .get()
                    .then(function(querySnapshot) {
                      querySnapshot.forEach(function(doc) {
                        const { videoUrl } = doc.data()
                        commit('appendVideoUrl', { offer, videoUrl })
                      })
                    })
                })

                commit('changeHeaderByRole', currentUser.roleId)
                commit('convertText')
              }
            })
          })
      }
    })
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    try {
      console.log('[actions] unBindRef :')
      unbindFirebaseRef('statuses')
      unbindFirebaseRef('agencies')
      unbindFirebaseRef('talents')
      unbindFirebaseRef('items')
    } catch (e) {}
  }),
}
