import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, {
  options: {
    customProperties: true,
  },
  theme: {
    primary: '#f25e39',
    secondary: '#9dd000',
    tertiary: '#7b7b7b',
    accent: '#aa8a58',
    success: '#4CAF50',
    info: '#593328',
    warning: '#FFC107',
    error: '#b71c1c',
  },
})
