import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { agency } from '@/constants/role'

const agencyRef = db.collection('agency')
const notificationsRef = db.collection('notifications')
const statusRef = db.collection('talent_status')
export const actions = {
  bindRef: firebaseAction(async ({ bindFirebaseRef, commit, state }) => {
    console.log('[actions] bindRef :', Date.now())
    await firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection('user')
          .where('uid', '==', user.uid)
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(async function(doc) {
              const currentUser = doc.data()
              if (currentUser) {
                let talentRef = {}
                if (currentUser.roleId === agency) {
                  talentRef = db
                    .collection('talent')
                    .where('agencyId', '==', currentUser.agencyId)
                } else {
                  talentRef = db.collection('talent')
                }
                await bindFirebaseRef('statuses', statusRef)
                await bindFirebaseRef('agencies', agencyRef)
                await bindFirebaseRef('notifications', notificationsRef)
                await bindFirebaseRef('items', talentRef)
                commit('changeHeaderByRole', currentUser.roleId)
                commit('convertText')
              }
            })
          })
      }
    })
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    try {
      unbindFirebaseRef('statuses')
      unbindFirebaseRef('agencies')
      unbindFirebaseRef('items')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),
}
