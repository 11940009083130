import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { agency } from '@/constants/role'

const talentStatusRef = db.collection('talent_status')
const offerStatusRef = db.collection('offer_status')

export const actions = {
  bindRef: firebaseAction(async ({ bindFirebaseRef, commit, state }) => {
    console.log('[actions] bindRef :', Date.now())
    await firebase.auth().onAuthStateChanged(user => {
      db.collection('user')
        .where('uid', '==', user.uid)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(async function(doc) {
            const currentUser = doc.data()
            if (currentUser) {
              let talentRef = {}
              let offerRef = {}
              if (currentUser.roleId === agency) {
                talentRef = db
                  .collection('talent')
                  .where('agencyId', '==', currentUser.agencyId)
                offerRef = db
                  .collection('offer')
                  .where('agencyId', '==', currentUser.agencyId)
              } else {
                talentRef = db.collection('talent')
                offerRef = db.collection('offer')
              }
              await bindFirebaseRef('talents', talentRef)
              await bindFirebaseRef('offers', offerRef)
              await bindFirebaseRef('talentStatus', talentStatusRef)
              await bindFirebaseRef('offerStatus', offerStatusRef)
              commit('createGraph')
              commit('changeDateRange')
            }
          })
        })
    })
  }),
  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    try {
      unbindFirebaseRef('talents')
      unbindFirebaseRef('offers')
      unbindFirebaseRef('talentStatus')
      unbindFirebaseRef('offerStatus')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),
}
