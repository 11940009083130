import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG, NS_ERROR_DIALOG } from '@/modules/store/_core/ns'

const introducerRef = db.collection('introducer')

export const actions = {
  // todo: firebase の action に差替え
  // see: https://qiita.com/uriuriuriu/items/2c8b86a77891a9f29295
  fetchRef: firebaseAction(async ({ bindFirebaseRef, commit }, id) => {
    await bindFirebaseRef('remote', introducerRef.doc(id))
    commit('mergeForm')
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    try {
      console.log('[actions] unBindRef :')
      unbindFirebaseRef('remote')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),

  submit({ commit, state }, payload) {
    introducerRef
      .doc(payload)
      .update(makePost(state.form))
      .then(() => {
        commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
      })
      .catch(() => {
        commit(
          `${NS_ERROR_DIALOG}/showDialog`,
          '事務所の新規追加に失敗しました。',
          {
            root: true,
          }
        )
      })
  },
}

function makePost(form) {
  const post = {}
  const stamp = firebase.firestore.FieldValue.serverTimestamp()
  post.name = form.name
  post.bank = form.bank
  post.margin = form.margin
  post.expire = form.expire
  post.phoneNumber = form.phoneNumber
  post.emailAddress = form.emailAddress
  // firestore用の現在時刻取得
  post.updatedAt = stamp
  return post
}
