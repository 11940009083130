import cloneDeep from 'lodash-es/cloneDeep'
import startOfMonth from 'date-fns/start_of_month'
import { calendarText } from '@/util/data_util'

const initState = {
  talentFromDate: calendarText(startOfMonth(new Date())),
  talentToDate: calendarText(new Date()),
  offerFromDate: calendarText(startOfMonth(new Date())),
  offerToDate: calendarText(new Date()),
  talents: [],
  offers: [],
  talentStatus: [],
  offerStatus: [],
  byPeriodCreatedAtOfferTotalNumber: '0',
  byPeriodCreatedAtOfferTotalPrice: '0',
  byPeriodPaidAtOfferTotalNumber: '0',
  byPeriodPaidAtOfferTotalPrice: '0',
  talentAllPeriod: [],
  offerAllPeriod: [],
  talentByPeriod: [
    {
      subheading: 'タレント申請',
      headline: '',
      percent: 0,
      value: 0,
      color: 'warning ',
    },
    {
      subheading: 'タレント承認',
      headline: '',
      percent: 0,
      value: 0,
      color: 'warning ',
    },
    {
      subheading: '出品申請',
      headline: '',
      percent: 0,
      value: 0,
      color: 'warning ',
    },
    {
      subheading: '受付中',
      headline: '',
      percent: 0,
      value: 0,
      color: 'warning ',
    },
    {
      subheading: '停止中',
      headline: '',
      percent: 0,
      value: 0,
      color: 'warning ',
    },
    {
      subheading: '退会',
      headline: '',
      percent: 0,
      value: 0,
      color: 'warning ',
    },
  ],
  offerByPeriod: [
    {
      subheading: '未対応',
      headline: '',
      percent: 0,
      value: 0,
      color: 'error',
    },
    {
      subheading: '拒否',
      headline: '',
      percent: 0,
      value: 0,
      color: 'error',
    },
    {
      subheading: '期限切れ',
      headline: '',
      percent: 0,
      value: 0,
      color: 'error',
    },
    {
      subheading: '納品済み',
      headline: '',
      percent: 0,
      value: 0,
      color: 'error',
    },
    {
      subheading: '支払い済み',
      headline: '',
      percent: 0,
      value: 0,
      color: 'error',
    },
    {
      subheading: 'キャンセル',
      headline: '',
      percent: 0,
      value: 0,
      color: 'error',
    },
  ],
}

export const defaultState = () => cloneDeep(initState)
