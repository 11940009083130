import { defaultState } from '@/modules/store/charity/edit/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  changeFormValue(state, payload) {
    console.log('[mutations] changeFormValue :', payload)
    const { prop, value } = payload
    state.form = { ...state.form, [prop]: value }
  },
  mergeForm(state) {
    const { form, remote } = state
    console.log('[mutation] mergeForm : ', remote)
    state.form = {
      ...form,
      ...remote,
    }
    // note: id はマージされないため個別対応
    state.form.id = remote.id
  },
  triggerFileSelect(state) {
    console.log('[mutations] triggerFileSelect :')
    const input = document.getElementsByClassName('charity-logo-file').item(0)
    input.click()
  },
  onFileChange(state, payload) {
    console.log('[mutations] onFileChange :', payload.target.files)
    const reader = new FileReader()
    reader.onload = event => {
      console.log('[mutations] onload :', event.target.result)
      state.form.logo = ''
      state.form.logoInfo.image = event.target.result
    }
    state.form.logoInfo.name = payload.target.files[0].name
    state.form.logoInfo.file = new Blob([payload.target.files[0]])
    reader.readAsDataURL(payload.target.files[0])
  },
}
