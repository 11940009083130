import { firebaseAction } from 'vuexfire'
import intersectionWith from 'lodash-es/intersectionWith'
import { db, firebase } from '@/plugins/firebase'
import { agency } from '@/constants/role'

const talentRef = db.collection('talent')
const reviewRef = db.collection('review')

const customComparator = (reviews, offers) => {
  return reviews.offerId == offers.id
}

export const actions = {
  bindRef: firebaseAction(async ({ bindFirebaseRef, state, commit }) => {
    console.log('[actions] BindRef :')
    await firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection('user')
          .where('uid', '==', user.uid)
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(async function(doc) {
              const currentUser = doc.data()
              let offerRef = {}
              if (currentUser) {
                if (currentUser.roleId === agency) {
                  offerRef = db
                    .collection('offer')
                    .where('agencyId', '==', currentUser.agencyId)
                } else {
                  offerRef = db.collection('offer')
                }
                await bindFirebaseRef('reviews', reviewRef)
                await bindFirebaseRef('talents', talentRef)
                await bindFirebaseRef('offers', offerRef)
                const reviewsList = intersectionWith(
                  state.reviews,
                  state.offers,
                  customComparator
                )
                commit('fetchItems', reviewsList)
                commit('convertText')
              }
            })
          })
      }
    })
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    try {
      console.log('[actions] unBindRef :')
      unbindFirebaseRef('talents')
      unbindFirebaseRef('items')
    } catch (e) {}
  }),

  convertText({ commit }) {
    commit('convertText')
  },
  changeStore({ commit, state }) {
    const reviewsList = intersectionWith(
      state.reviews,
      state.offers,
      customComparator
    )
    commit('fetchItems', reviewsList)
    commit('convertText')
  },
  // note: Reviews, Offices情報が揃っていることが前提
  async submit({ dispatch, state }, payload) {
    console.log('[actions] submit :', payload)
    await reviewRef.doc(payload.id).update({ isActive: state.status })
    dispatch('changeStore')
  },
}
