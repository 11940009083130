import { actions } from './_actions'
import { getters } from './_getters'
import { mutations } from './_mutations'
import { defaultState } from './_state'
export const webNewsEditStore = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: defaultState,
}
