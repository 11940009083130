import { filterTableItems } from '@/util/table_util'

export const getters = {
  agencies(state) {
    return state.agencies
  },
  statuses(state) {
    return state.statuses
  },
  search(state) {
    return state.search
  },
  filterType(state) {
    return state.filterType
  },
  filterItems(state) {
    return state.filterItems
  },
  header(state) {
    return state.header
  },
  tableItems(state) {
    return filterTableItems(state)
  },
}
