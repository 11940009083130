export function roundAt(target, digit) {
  return Math.round(target * 10 ** digit) / 10 ** digit
}
export function formatCount(n) {
  return roundAt(n, 0).toLocaleString()
}
export function formatCurrency(n) {
  const currencyOption = { style: 'currency', currency: 'JPY' }
  return roundAt(n, 0).toLocaleString('ja', currencyOption)
}
export function formatPercent(n) {
  const percentOption = {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  return roundAt(n, 4).toLocaleString('ja', percentOption)
}
