import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG } from '@/modules/store/_core/ns'
import { accepting } from '@/constants/talentStatus'

const functions = firebase.functions()
const talentAllowSendMail = functions.httpsCallable('talentAllowSendMail')
const agencyRef = db.collection('agency')
const introducerRef = db.collection('introducer')
const categoryRef = db.collection('category')
const charityDestinationRef = db.collection('charityDestination')
const statusRef = db.collection('talent_status')
const talentRef = db.collection('talent')
const storageRef = firebase.storage().ref()

export const actions = {
  fetchRef: firebaseAction(async ({ bindFirebaseRef, commit, state }, id) => {
    await bindFirebaseRef('select.agencies', agencyRef)
    await bindFirebaseRef('select.introducers', introducerRef)
    await bindFirebaseRef('select.categories', categoryRef)
    await bindFirebaseRef('select.statuses', statusRef)
    await bindFirebaseRef('select.charityDestination', charityDestinationRef)
    await bindFirebaseRef('remote', talentRef.doc(id))
    commit('mergeForm')
    commit('updateSelectedAgency')
    commit('updateSelectedIntroducer')
    commit('updateSelectedCategories')
    commit('updateSelectedStatus')
    commit('convertText')
    commit('updateSelectedCharityDestination')
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    try {
      unbindFirebaseRef('select.agencies')
      unbindFirebaseRef('select.introducers')
      unbindFirebaseRef('select.categories')
      unbindFirebaseRef('select.statuses')
      unbindFirebaseRef('select.charityDestination')
      unbindFirebaseRef('remote')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),

  async submit({ state, commit }, id) {
    console.log('[actions] submit :', id)
    const talentDoc = await talentRef.doc(id).get()
    const talentData = talentDoc.data()
    console.log(state)
    if (talentData.status != '3' && state.form.status === accepting) {
      const mdata = {
        destination: state.form.emailAddress,
        talentId: state.form.id,
      }
      talentAllowSendMail(mdata)
    }
    if (state.form.avatarInfo.file) {
      const path = 'avatar/' + id + '/' + state.form.avatarInfo.name
      storageRef
        .child(path)
        .put(state.form.avatarInfo.file)
        .then(function(snapshot) {
          snapshot.ref.getDownloadURL().then(async function(downloadURL) {
            await talentRef.doc(id).update(makePost(state.form, downloadURL))
            commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
          })
        })
    } else {
      await talentRef.doc(id).update(makePost(state.form))
      commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
    }
  },
}

function makePost(form, path) {
  const stamp = firebase.firestore.FieldValue.serverTimestamp()
  const {
    name,
    avatar,
    message,
    price,
    emailAddress,
    introducerId,
    agencyId,
    category,
    status,
    isSns,
    isCharity,
    charity,
  } = form

  return {
    name,
    avatar: path || avatar,
    message,
    price,
    emailAddress,
    category,
    introducerId,
    agencyId,
    status,
    isSns: !!isSns,
    isCharity: !!isCharity,
    charity,
    // firestore用の現在時刻取得
    updatedAt: stamp,
  }
}
