import { defaultState } from '@/modules/store/agency/create/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  changeFormValue(state, payload) {
    console.log('[mutations] changeFormValue :', payload)
    const { prop, value } = payload
    console.log(payload)
    state.form = { ...state.form, [prop]: value }
  },
  changeBankValue(state, payload) {
    console.log('[mutations] changeBankValue :', payload)
    const prop = payload.prop
    let value = payload.value
    if (!value) {
      value = ''
    }
    state.form.bank = { ...state.form.bank, [prop]: value }
  },
}
