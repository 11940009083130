import { defaultState } from '@/modules/store/agency/edit/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  initializeFormValue(state, payload) {
    console.log('[mutations] initializeFormValue :', payload)
    state.form = { ...payload }
  },
  changeFormValue(state, payload) {
    console.log('[mutations] changeFormValue :', payload)
    const { prop, value } = payload
    console.log(payload)
    state.form = { ...state.form, [prop]: value }
  },
  changeBankValue(state, payload) {
    console.log('[mutations] changeBankValue :', payload)
    const prop = payload.prop
    let value = payload.value
    if (!value) {
      value = ''
    }
    state.form.bank = { ...state.form.bank, [prop]: value }
  },
  mergeForm(state) {
    const { form, remote } = state
    console.log('[mutation] mergeForm : ', remote)
    state.form = {
      ...form,
      ...remote,
    }
    // note: id はマージされないため個別対応
    state.form.id = remote.id
  },
}
