import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG, NS_ERROR_DIALOG } from '@/modules/store/_core/ns'

const agencyRef = db.collection('agency')
export const actions = {
  fetchRef: firebaseAction(async ({ bindFirebaseRef, commit }, id) => {
    await bindFirebaseRef('remote', agencyRef.doc(id))
    commit('mergeForm')
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    try {
      console.log('[actions] unBindRef :')
      unbindFirebaseRef('remote')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),

  submit({ commit, state }, id) {
    // note: subscribe 後に完了通知
    agencyRef
      .doc(id)
      .update(makePost(state.form))
      .then(() => {
        commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
      })
      .catch(() => {
        commit(
          `${NS_ERROR_DIALOG}/showDialog`,
          '事務所の編集に失敗しました。',
          {
            root: true,
          }
        )
      })
  },
}

function makePost(form) {
  const post = {}
  const stamp = firebase.firestore.FieldValue.serverTimestamp()
  post.name = form.name
  post.address = form.address
  post.staff = form.staff
  post.phoneNumber = form.phoneNumber
  post.emailAddress = form.emailAddress
  post.memo = form.memo
  post.bank = form.bank
  // firestore用の現在時刻取得
  post.updatedAt = stamp
  return post
}
