export const getters = {
  talentStatusLabels(state) {
    return state.talentStatus.map(t => t.name).reverse()
  },
  offerStatusLabels(state) {
    return state.offerStatus.map(o => o.name).reverse()
  },
  talentFromDate(state) {
    return state.talentFromDate
  },
  talentToDate(state) {
    return state.talentToDate
  },
  offerFromDate(state) {
    return state.offerFromDate
  },
  offerToDate(state) {
    return state.offerToDate
  },
  talentAllPeriod(state) {
    return state.talentAllPeriod
  },
  offerAllPeriod(state) {
    return state.offerAllPeriod
  },
  talentByPeriod(state) {
    return state.talentByPeriod
  },
  offerByPeriod(state) {
    return state.offerByPeriod
  },
  byPeriodCreatedAtOfferTotalNumber(state) {
    return state.byPeriodCreatedAtOfferTotalNumber
  },
  byPeriodCreatedAtOfferTotalPrice(state) {
    return state.byPeriodCreatedAtOfferTotalPrice
  },
  byPeriodPaidAtOfferTotalNumber(state) {
    return state.byPeriodPaidAtOfferTotalNumber
  },
  byPeriodPaidAtOfferTotalPrice(state) {
    return state.byPeriodPaidAtOfferTotalPrice
  },
}
