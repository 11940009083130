import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG, NS_ERROR_DIALOG } from '@/modules/store/_core/ns'

const agencyRef = db.collection('agency')
const roleRef = db.collection('role')
const userRef = db.collection('user')
export const actions = {
  fetchRef: firebaseAction(async ({ bindFirebaseRef, commit }, id) => {
    await bindFirebaseRef('select.agencies', agencyRef)
    await bindFirebaseRef('select.roles', roleRef)
    await bindFirebaseRef('remote', userRef.doc(id))
    commit('mergeForm')
    commit('updateSelectedAgency')
    commit('updateSelectedRole')
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef }) => {
    try {
      unbindFirebaseRef('select.agencies')
      unbindFirebaseRef('select.roles')
      unbindFirebaseRef('remote')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),

  submit({ commit, state }, id) {
    // note: subscribe 後に完了通知
    if (state.isCheck) {
      firebase
        .auth()
        .sendPasswordResetEmail(state.form.emailAddress)
        .catch(function(error) {
          alert(error)
        })
    }
    userRef
      .doc(id)
      .update(makePost(state.form, state.isAgency))
      .then(() => {
        commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
      })
      .catch(() => {
        commit(
          `${NS_ERROR_DIALOG}/showDialog`,
          'ユーザーの編集に失敗しました。もう一度お試しください。',
          {
            root: true,
          }
        )
      })
  },
}

function makePost(form, isAgency) {
  const post = {}
  const stamp = firebase.firestore.FieldValue.serverTimestamp()
  post.name = form.name
  post.emailAddress = form.emailAddress
  if (isAgency) {
    post.agencyId = form.agencyId
  }
  post.roleId = form.roleId
  // firestore用の現在時刻取得
  post.updatedAt = stamp
  return post
}
