import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG, NS_ERROR_DIALOG } from '@/modules/store/_core/ns'

const newsRef = db
  .collection('web')
  .doc('news')
  .collection('list')

export const actions = {
  submit({ commit, state }) {
    newsRef
      .add(makePost(state.form))
      .then(() => {
        commit('initializeState')
        commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
      })
      .catch(() => {
        commit(
          `${NS_ERROR_DIALOG}/showDialog`,
          'お知らせの新規追加に失敗しました。',
          {
            root: true,
          }
        )
      })
  },
}

function makePost(form) {
  const stamp = firebase.firestore.FieldValue.serverTimestamp()
  const { title, content, isNotice } = form
  return {
    title,
    content,
    isNotice,
    createdAt: stamp,
    updatedAt: stamp,
  }
}
