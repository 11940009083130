import cloneDeep from 'lodash-es/cloneDeep'
import {
  TEXT_FILTER,
  STATUS_FILTER,
  DATE_FILTER,
} from '@/constants/filterSearchType'

const initState = {
  search: '',
  items: [],
  offers: [],
  talents: [],
  statuses: [],
  filterType: {
    id: 'name',
    label: '名前',
  },
  filterItems: [
    {
      id: 'name',
      label: '依頼主',
      type: TEXT_FILTER,
    },
    {
      id: 'talentText',
      label: 'タレント',
      type: TEXT_FILTER,
    },
    {
      id: 'statusText',
      label: 'ステータス',
      type: STATUS_FILTER,
    },
    {
      id: 'createdAtText',
      label: '依頼日',
      type: DATE_FILTER,
    },
    {
      id: 'deliverdAtText',
      label: '納品日',
      type: DATE_FILTER,
    },
    {
      id: 'paidAtText',
      label: '決済日',
      type: DATE_FILTER,
    },
  ],
  searchFilterItems: [],
  header: [
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
    },
    {
      text: '依頼主',
      value: 'name',
      sortable: true,
    },
    {
      text: 'メールアドレス',
      value: 'emailAddress',
      sortable: false,
    },
    {
      text: '宛名',
      value: 'toName',
      sortable: false,
    },
    {
      text: 'タレント',
      value: 'talentText',
      sortable: true,
    },
    {
      text: 'リクエスト',
      value: 'message',
      sortable: false,
    },
    {
      text: '販売価格',
      value: 'price',
      sortable: true,
    },
    {
      text: '事務所',
      value: 'agencyText',
      sortable: false,
    },
    {
      text: 'ビデオ',
      value: 'videoUrl',
      width: '100px',
      sortable: false,
    },
    // {
    //   text: 'タイプ',
    //   value: 'offertype',
    //   sortable: false,
    // },
    {
      text: 'ステータス',
      value: 'statusText',
      sortable: true,
    },
    {
      text: '依頼日',
      value: 'createdAtText',
      width: '180px',
      sortable: true,
    },
    {
      text: '期限',
      value: 'limitedAtText',
      width: '180px',
      sortable: true,
    },
    {
      text: '納品日',
      value: 'deliverdAtText',
      width: '180px',
      sortable: true,
    },
    {
      text: '決済日',
      value: 'paidAtText',
      width: '180px',
      sortable: true,
    },
    {
      text: 'ビデオ紹介',
      value: 'videoUseableText',
      width: '80px',
      sortable: true,
    },
  ],
}

export const defaultState = () => cloneDeep(initState)
