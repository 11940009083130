export const getters = {
  form(state) {
    return state.form
  },
  user(state) {
    return state.user
  },
  isSignedIn(state) {
    return state.status
  },
  role(state) {
    return state.user.roleId
  },
}
