import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG, NS_ERROR_DIALOG } from '@/modules/store/_core/ns'
import { offerPaid, offerAgencyConfirm } from '@/constants/offerStatus'

const agencyRef = db.collection('agency')
const talentRef = db.collection('talent')
const offerRef = db.collection('offer')
const statusRef = db.collection('offer_status')

const functions = firebase.functions()
const charge = functions.httpsCallable('charge')
const completeSendMail = functions.httpsCallable('completeSendMail')
const agencyConfirmSendMail = functions.httpsCallable('agencyConfirmSendMail')

export const actions = {
  // todo: firebase の action に差替え
  // see: https://qiita.com/uriuriuriu/items/2c8b86a77891a9f29295
  fetchRef: firebaseAction(
    async ({ bindFirebaseRef, commit, state }, payload) => {
      console.log('[actions] fetchRef :' + payload)
      await bindFirebaseRef('agencies', agencyRef)
      await bindFirebaseRef('remote', offerRef.doc(payload.id))
      await bindFirebaseRef('select.statuses', statusRef)
      await bindFirebaseRef('talent', talentRef.doc(state.remote.talentId))
      commit('mergeForm')
      commit('convertText')
      commit('updateSelectedStatus', { role: payload.role })
    }
  ),

  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    console.log('[actions] unBindRef :')
    try {
      unbindFirebaseRef('agencies')
      unbindFirebaseRef('remote')
      unbindFirebaseRef('select.statuses')
      unbindFirebaseRef('talent')
    } catch (e) {
      console.log('unBindRef error : ', e)
    }
  }),

  async submit({ commit, state }, id) {
    console.log('[actions] submit :', state.form)
    const { status } = state.form
    const paidAt = firebase.firestore.FieldValue.serverTimestamp()
    if (state.form.status === offerPaid) {
      if (state.form.initial) {
        console.log('initial offer :', state.form)
        const completion = true
        await offerRef.doc(id).update({ status, paidAt, completion })
        commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
      } else {
        try {
          const talentDoc = await talentRef.doc(state.form.talentId).get()
          const talentData = talentDoc.data()
          const source = state.form.payInfo ? state.form.payInfo.source : ''
          const charityPercent =
            talentData.isCharity && talentData.charity
              ? talentData.charity.percent
              : ''
          const chargeData = {
            amount: state.form.price,
            discountPrice: state.form.discountPrice,
            description: id,
            customerId: state.form.customerId,
            email: state.form.emailAddress,
            talentData,
            charityPercent,
            source,
          }
          await charge(chargeData)
          await offerRef.doc(id).update({ status, paidAt })
          const data = {
            destination: state.form.emailAddress,
            offerId: id,
          }
          completeSendMail(data)
          commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
        } catch (err) {
          // fetch와 response.json에서 발행한 에러 모두를 여기서 잡습니다.
          commit(
            `${NS_ERROR_DIALOG}/showDialog`,
            '決済に失敗しました。(' + err.message + ')',
            {
              root: true,
            }
          )
        }
      }
    } else if (state.form.status === offerAgencyConfirm) {
      const { status } = state.form
      await offerRef.doc(id).update({ status })
      const data = {
        offerId: id,
      }
      agencyConfirmSendMail(data)
      commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
    } else {
      const { status } = state.form
      await offerRef.doc(id).update({ status })
      commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
    }
  },
}
