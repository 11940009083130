import { firebaseAction } from 'vuexfire'
import { db } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG } from '@/modules/store/_core/ns'

const agencyRef = db.collection('agency')
const roleRef = db.collection('role')
const userRef = db.collection('user')

export const actions = {
  // todo: firebase の action に差替え
  // see: https://qiita.com/uriuriuriu/items/2c8b86a77891a9f29295
  bindRef: firebaseAction(async ({ bindFirebaseRef, commit }, role) => {
    console.log('[actions] BindRef :')
    await bindFirebaseRef('agencies', agencyRef)
    await bindFirebaseRef('roles', roleRef)
    await bindFirebaseRef('items', userRef)
    commit('changeHeaderByRole', role)
    commit('convertText')
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef }) => {
    console.log('[actions] unBindRef :')
    try {
      unbindFirebaseRef('agencies')
      unbindFirebaseRef('roles')
      unbindFirebaseRef('items')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),

  setIdForRemove({ commit }, payload) {
    commit('setIdForRemove', payload)
  },

  async submit({ commit }, payload) {
    console.log('[actions] submit :', payload)
    await Promise.all([userRef.doc(payload).delete()])
    commit('setIdForRemove', '')
    // note: subscribe 後に完了通知
    commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
  },
}
