<template>
  <v-toolbar color="primary" fixed dark app>
    <v-toolbar-side-icon @click.stop="toggleDrawer()" />
    <v-spacer />
    <v-toolbar-title
      :nudge-right="140"
      :nudge-bottom="10"
      offset-y
      origin="center center"
      transition="scale-transition"
    >
      ようこそ、{{ user.name }}さん
    </v-toolbar-title>
    <v-menu
      :nudge-right="140"
      :nudge-bottom="10"
      offset-y
      origin="center center"
      transition="scale-transition"
    >
      <v-btn
        slot="activator"
        @click="logOut()"
        depressed
        outline
        dark
        flat
      >
        ログアウト
      </v-btn>
      <v-btn
        slot="activator"
        @click="linkManual()"
        depressed
        outline
        dark
        flat
      >
        マニュアル
      </v-btn>
    </v-menu>
  </v-toolbar>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { firebase } from '@/plugins/firebase'
import { NS_LOGIN } from '@/modules/store/_core/ns'

export default {
  name: 'AppToolbar',
  computed: {
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav
    },
    ...mapActions(NS_LOGIN, ['out']),
    ...mapGetters(NS_LOGIN, ['user']),
  },
  methods: {
    toggleDrawer() {
      this.$store.commit('toggleDrawer')
    },
    logOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit(
            `${NS_LOGIN}/onAuthStateChanged`,
            {},
            {
              root: true,
            }
          )
          this.$store.commit(`${NS_LOGIN}/onUserStatusChanged`, false, {
            root: true,
          })
          return this.$nuxt.$router.push('/login')
        })
        .catch(error => {
          alert(`ログアウト時にエラーが発生しました (${error})`)
        })
    },
    linkManual() {
      window.open(
        'https://drive.google.com/drive/folders/1nUrxvHv5nhvNH-8MopvEAxUHptmD-I95'
      )
    },
  },
}
</script>
