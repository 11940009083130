import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  form: {
    name: '',
    emailAddress: '',
    agencyId: '',
    roleId: '',
  },
  select: {
    roles: [],
    agencies: [],
  },
  selectedRole: null,
  selectedAgency: null,
  isCheck: false,
  remote: {},
  isAgency: false,
}

export const defaultState = () => cloneDeep(initState)
