import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  form: {
    mailAddress: '',
    pass: '',
  },
  user: {},
  status: false,
}

export const defaultState = () => cloneDeep(initState)
