const offerNotCompatible = '1' // 未対応
const offerRejected = '2' // 拒否
const offerExpired = '3' // 期限切れ
const offerCompleted = '4' // 納品済み
const offerPaid = '5' // 支払い済み
const offerCancel = '6' // キャンセル
const offerAgencyConfirm = '7' // 事務所承認

export {
  offerNotCompatible,
  offerRejected,
  offerExpired,
  offerCompleted,
  offerPaid,
  offerCancel,
  offerAgencyConfirm,
}
