export const getters = {
  form(state) {
    return state.form
  },
  select(state) {
    return state.select
  },
  selectedCategories(state) {
    return state.selectedCategories
  },
  selectedAgency(state) {
    return state.selectedAgency
  },
  selectedIntroducer(state) {
    return state.selectedIntroducer
  },
  selectedStatus(state) {
    return state.selectedStatus
  },
  selectedSns(state) {
    return state.selectedSns
  },
  isSolo(state) {
    return state.isSolo
  },
  selectedCharityDestination(state) {
    return state.selectedCharityDestination
  },
}
