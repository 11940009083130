import { defaultState } from '@/modules/store/common/AppDrawer/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  setMenu(state, payload) {
    console.log('[mutations] setMenu :', Date.now())
    state.menu = payload
  },
}
