import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'

const dict = {
  custom: {
    name: {
      required: '名前が入力されていません',
    },
    phonetic: {
      required: 'ふりがなが入力されていません',
    },
    emailAddress: {
      required: 'メールアドレスが入力されていません',
      email: 'メールアドレスは正しい形式で入力してください',
    },
    selectedAgency: {
      required: '事務所が選択されていません',
    },
    selectedRole: {
      required: '役割が選択されていません',
    },
    selectedStatus: {
      required: 'ステータスが選択されていません',
    },
    selectedSns: {
      required: 'SNS種別が選択されていません',
    },
    image: {
      required: 'サムネイルが添付されていません',
    },
    price: {
      required: '価格が入力されていません',
      numeric: '数字のみで入力してください',
      min_value: '価格は1500円以上で入力してください',
      max_value: '価格は30000円以下で入力してください',
    },
    message: {
      required: '紹介文が入力されていません',
    },
    handle: {
      required: 'ハンドルIDが入力されていません',
      alpha_dash: '半角英数字で入力してください',
    },
    termsAgree: {
      required: '利用規約が選択されていません',
    },
    introducerId: {
      alpha_dash: '半角英数字で入力してください',
    },
    newPass: {
      required: 'パスワードが入力されていません',
      alpha_dash: '半角英数字で入力してください',
    },
    confirmPass: {
      required: '確認用パスワードが入力されていません',
      confirmed: 'パスワードが一致していません',
    },
    staff: {
      required: '担当者が入力されていません',
    },
    address: {
      required: '住所が入力されていません',
    },
    phoneNumber: {
      required: '電話番号が入力されていません',
    },
    margin: {
      required: '紹介料率が入力されていません',
    },
    bankAccountHolder: {
      required: '口座名義が入力されていません',
    },
    bankName: {
      required: '銀行名が入力されていません',
    },
    bankBranchName: {
      required: '支店名または支店コードが入力されていません',
    },
    bankNumber: {
      required: '口座番号が入力されていません',
      numeric: '数字のみで入力してくだい',
    },
    expire: {
      required: '紹介料期限が入力されていません',
    },
    charityname: {
      required: '団体名が入力されていません',
    },
    link: {
      required: 'リンク先が入力されていません',
    },
    memo: {
      required: 'メモが入力されていません',
    },
  },
}

Validator.localize('ja', dict)
Vue.use(VeeValidate, { locale: 'ja', inlect: false })
