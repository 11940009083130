import { convertDateToText, dateFileText } from '@/util/data_util'
import { exportCsvFile } from '@/util/file_util'
import { admin } from '@/constants/role'
import { defaultState } from '@/modules/store/offer/list/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  updateFilterType(state, payload) {
    state.filterType = payload
  },
  updateSearchValue(state, payload) {
    state.search = payload
  },
  changeHeaderByRole(state, roleId) {
    if (admin !== roleId) {
      // 意図的に空配列を渡さないと、headerの読み込みでエラーになるのでsplice禁止
      delete state.header[2]
    }
  },
  convertText(state) {
    const { items, talents, agencies, statuses } = state
    state.items = items.map(item => {
      const targetStatus = statuses.find(status => status.id === item.status)
      const targetAgency = agencies.find(agency => agency.id === item.agencyId)
      const targetTalent = talents.find(talent => talent.id === item.talentId)
      item.statusText = targetStatus ? targetStatus.name : ''
      item.agencyText = targetAgency ? targetAgency.name : ''
      item.talentText = targetTalent ? targetTalent.name.name : ''
      item.createdAtText = item.createdAt
        ? convertDateToText(item.createdAt.toDate())
        : ''
      item.limitedAtText = item.limitedAt
        ? convertDateToText(item.limitedAt.toDate())
        : ''

      // object(Timestamp) or undefined or boolean が返ってくる
      item.deliverdAtText = item.deliveredAt
        ? convertDateToText(item.deliveredAt.toDate())
        : ''
      item.paidAtText = item.paidAt
        ? convertDateToText(item.paidAt.toDate())
        : ''
      item.videoUseableText = item.videoUseable ? '〇' : '×'
      return item
    })
  },
  exportCsv(state) {
    const headers = [
      'ID',
      '依頼主',
      'メールアドレス',
      '宛名',
      'タレント',
      '販売価格',
      '事務所',
      'ビデオ',
      'ステータス',
      '依頼日',
      '期限',
      '納品日',
      '決済日',
      'ビデオ紹介',
    ]
    const records = state.items.map(item => {
      const {
        id,
        name,
        emailAddress,
        toName,
        talentText,
        price,
        agencyText,
        videoUrl,
        statusText,
        createdAtText,
        limitedAtText,
        deliverdAtText,
        paidAtText,
        videoUseableText,
      } = item
      return [
        id,
        name,
        emailAddress,
        toName,
        talentText,
        price,
        agencyText,
        videoUrl,
        statusText,
        createdAtText,
        limitedAtText,
        deliverdAtText,
        paidAtText,
        videoUseableText,
      ]
    })
    records.unshift(headers)
    const newRecords = records.map(record => {
      const newRecord = []
      for (let i = 0; i < record.length; i++) {
        if (typeof record[i] == 'string' && record[i].includes(',')) {
          const reRecord = '"' + record[i] + '"'
          newRecord.push(reRecord)
        } else {
          newRecord.push(record[i])
        }
      }
      return newRecord
    })
    const data = newRecords.map(record => record.join(',')).join('\r\n')
    const dateText = dateFileText()
    const fileName = 'Fanglee_offer' + dateText
    exportCsvFile(data, fileName)
  },

  appendVideoUrl(state, { offer, videoUrl }) {
    state.items = state.items.map(o => {
      if (o.id === offer.id) {
        o.videoUrl = videoUrl
      }
      return o
    })
  },

  updateFilter(state, payload) {
    console.log('[mutaiton] updateFilter : ', payload)
    state.searchFilterItems = payload
  },
}
