import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG } from '@/modules/store/_core/ns'

const charityRef = db.collection('charityDestination')
const storageRef = firebase.storage().ref()

export const actions = {
  // todo: firebase の action に差替え
  // see: https://qiita.com/uriuriuriu/items/2c8b86a77891a9f29295
  fetchRef: firebaseAction(async ({ bindFirebaseRef, commit }, id) => {
    await bindFirebaseRef('remote', charityRef.doc(id))
    commit('mergeForm')
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    try {
      console.log('[actions] unBindRef :')
      unbindFirebaseRef('remote')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),

  async submit({ commit, state }, payload) {
    if (state.form.logoInfo.file) {
      const path = 'logo/' + payload + '/' + state.form.logoInfo.name
      storageRef
        .child(path)
        .put(state.form.logoInfo.file)
        .then(function(snapshot) {
          snapshot.ref.getDownloadURL().then(async function(downloadURL) {
            await charityRef.doc(payload).set(makePost(state.form, downloadURL))
            commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
          })
        })
    } else {
      await charityRef.doc(payload).update(makePost(state.form))
      commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
    }
  },
}

function makePost(form, path) {
  const post = {}
  const stamp = firebase.firestore.FieldValue.serverTimestamp()
  post.name = form.name
  post.link = form.link
  post.logo = path || form.logo
  post.memo = form.memo
  // firestore用の現在時刻取得
  post.updatedAt = stamp
  return post
}
