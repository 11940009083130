import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  webRef: null,
  talentRef: null,
  pickupDocRef: null,
  newsList: [],
}

export const defaultState = () => cloneDeep(initState)
