import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  form: {
    id: '',
    name: {
      name: '',
      phonetic: '',
    },
    emailAddress: '',
    avatar: '',
    avatarInfo: {
      file: '',
      name: '',
      image: '',
    },
    category: [],
    message: '',
    agencyId: '',
    price: 1500,
    sns: '',
    handle: '',
    status: '',
    introducerId: '',
    isSns: false,
    isCharity: false,
    level: '1',
    charity: {
      destination: [],
      payment: '1', // 1：タレント支払, 2：Fanglee支払い
      percent: '0',
      max: '0',
    },
    // bank: {
    //   bankAccountHolder: '',
    //   bankBranchName: '',
    //   bankType: '',
    //   bankName: '',
    //   bankNumber: '',
    // },
    createdAt: '',
    lastDeliveredAt: '',
  },
  select: {
    categories: [],
    agencies: [],
    introducers: [],
    statuses: [],
    charityDestination: [],
    // bankTypeSelect: [
    //   { text: '普通', id: 1 },
    //   { text: '当座', id: 2 },
    //   { text: '貯蓄', id: 3 },
    // ],
  },
  selectedCategories: [],
  selectedCharityOrganization: [],
  selectedAgency: null,
  selectedIntroducer: null,
  selectedStatus: null,
  selectedCharityDestination: [],
  convertItems: {
    createdAtText: '',
    lastDeliveredAtText: '',
  },
}
export const defaultState = () => cloneDeep(initState)
