export const getters = {
  form(state) {
    return state.form
  },
  convertItems(state) {
    return state.convertItems
  },
  select(state) {
    return state.select
  },
  selectedStatus(state) {
    return state.selectedStatus
  },
}
