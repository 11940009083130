import { convertDateToText } from '@/util/data_util'
import { defaultState } from '@/modules/store/talent_request/list/_state'
import { admin } from '@/constants/role'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  fetchItems(state, payload) {
    console.log('[mutations] fetchItems :')
    state.items = payload
  },
  updateFilterType(state, payload) {
    state.filterType = payload
  },
  updateSearchValue(state, payload) {
    state.search = payload
  },
  fetchOffices(state, payload) {
    state.offices = payload
  },
  convertText(state) {
    const { items } = state
    state.items = items.map(item => {
      // ここでエラーが走る
      item.createdAtText = item.createdAt
        ? convertDateToText(item.createdAt.toDate())
        : ''
      return item
    })
  },
  appendReview(state, { offer, reviews }) {
    state.items = state.items.map(o => {
      if (o.id === offer.id) {
        o.review = reviews
      }
      return o
    })
  },
  changeHeaderByRole(state, roleId) {
    if (admin !== roleId) {
      // 意図的に空配列を渡さないと、headerの読み込みでエラーになるのでsplice禁止
      delete state.header[2]
    }
  },
  updateFilter(state, payload) {
    console.log('[mutaiton] updateFilter : ', payload)
    state.searchFilterItems = payload
  },
  updateIsActive(state, payload) {
    console.log('[mutaiton] updateIsActive : ', payload)
    state.status = payload
  },
}
