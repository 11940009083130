import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  form: {
    id: '',
    name: '',
    toName: '',
    emailAddress: '',
    message: '',
    offertype: '',
    talentId: '',
    agencyId: '',
    status: '',
    createdAt: '',
    deliverdAt: '',
    payInfo: {
      brand: '',
      country: '',
      token: '',
    },
    price: 0,
  },
  select: {
    statuses: [],
  },
  selectedStatus: '',
  talent: {},
  agencies: [],
  convertItems: {
    talentName: '',
    agencyName: '',
    createdAtText: '',
    deliveredAtText: '',
  },
}

export const defaultState = () => cloneDeep(initState)
