export const getters = {
  menu(state) {
    return state.menu
  },
  mini(state) {
    return state.mini
  },
  scrollSettings(state) {
    return state.scrollSettings
  },
}
