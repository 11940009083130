import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG, NS_ERROR_DIALOG } from '@/modules/store/_core/ns'

const newsRef = db
  .collection('web')
  .doc('news')
  .collection('list')

export const actions = {
  fetchRef: firebaseAction(async ({ bindFirebaseRef, commit }, id) => {
    await bindFirebaseRef('remote', newsRef.doc(id))
    commit('mergeForm')
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef }) => {
    try {
      unbindFirebaseRef('remote')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),

  submit({ commit, state }, id) {
    newsRef
      .doc(id)
      .update(makePost(state.form))
      .then(() => {
        commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
      })
      .catch(() => {
        commit(
          `${NS_ERROR_DIALOG}/showDialog`,
          'お知らせの編集に失敗しました。もう一度お試しください。',
          {
            root: true,
          }
        )
      })
  },
}

function makePost(form) {
  const stamp = firebase.firestore.FieldValue.serverTimestamp()
  const { title, content, isNotice } = form
  return {
    title,
    content,
    isNotice,
    updatedAt: stamp,
  }
}
