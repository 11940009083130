import { defaultState } from '@/modules/store/talent/create/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  changeFormValue(state, payload) {
    console.log('[mutations] changeFormValue :', payload)
    const { prop, value } = payload
    state.form = { ...state.form, [prop]: value }
  },
  changeBankValue(state, payload) {
    console.log('[mutations] changeBankValue :', payload)
    const { prop, value } = payload
    state.form.bank = { ...state.form.bank, [prop]: value }
  },
  triggerFileSelect(state) {
    console.log('[mutations] triggerFileSelect :')
    const input = document.getElementsByClassName('talent-avatar-file').item(0)
    input.click()
  },
  onFileChange(state, payload) {
    console.log('[mutations] onFileChange :', payload.target.files)
    const reader = new FileReader()
    reader.onload = event => {
      console.log('[mutations] onload :', event.target.result)
      state.form.avatarInfo.image = event.target.result
    }
    state.form.avatarInfo.name = payload.target.files[0].name
    state.form.avatarInfo.file = new Blob([payload.target.files[0]])
    reader.readAsDataURL(payload.target.files[0])
  },
  initializeCategories(state, payload) {
    console.log('[mutations] initializeCategories :', payload)
    state.select.categories = payload
  },
  initializeOffices(state, payload) {
    console.log('[mutations] initializeOffices :')
    state.select.offices = payload
  },
  updateSelectedCategories(state, payload) {
    console.log('[mutations] updateSelectedCategories :', payload)
    const { form, select } = state
    state.selectedCategories = []
    if (payload) {
      state.selectedCategories = payload
      form.category = []
      for (const item of payload) {
        form.category.push({ categoryId: item.id, name: item.name })
      }
    } else {
      for (const item of form.category) {
        const cate = select.categories.find(c => c.id === item.categoryId)
        state.selectedCategories.push(cate)
      }
    }
  },
  updateSelectedAgency(state, payload) {
    console.log('[mutations] updateSelectedAgency :', payload)
    const { select, form } = state
    const agency = payload ? payload.id : ''
    if (agency) {
      state.selectedAgency = select.agencies.find(o => o.id === agency)
      form.agencyId = state.selectedAgency.id
    } else {
      form.agencyId = ''
    }
  },
  updateSelectedIntroducer(state, payload) {
    console.log('[mutations] updateSelectedIntroducer :', payload)
    const { select, form } = state
    let introducerId = form.introducerId ? form.introducerId : ''
    if (payload) {
      if (payload.prop === 'introducerChange') {
        if (payload.value) {
          introducerId = payload.value.id
        } else {
          introducerId = ''
        }
      }
    }
    state.selectedIntroducer = select.introducers.find(
      o => o.id === introducerId
    )
    form.introducerId = state.selectedIntroducer
      ? state.selectedIntroducer.id
      : ''
  },
  updateSelectedStatus(state, payload) {
    console.log('[mutations] updateSelectedStatus :', payload)
    const { select, form } = state
    const status = payload ? payload.id : form.status
    state.selectedStatus = select.statuses.find(c => c.id === status)
    form.status = state.selectedStatus ? state.selectedStatus.id : null
  },
  updateSelectedSns(state, payload) {
    console.log('[mutations] updateSelectedSns :', payload)
    const { select, form } = state
    const sns = payload ? payload.id : form.sns
    state.selectedSns = select.sns.find(c => c.id === sns)
    form.sns = state.selectedSns ? state.selectedSns.name : null
  },
  updateIsSolo(state) {
    state.isSolo = !state.isSolo
  },
  changeCharityValue(state, payload) {
    console.log('[mutations] changeCharityValue :', payload)
    const { prop, value } = payload
    state.form.charity = { ...state.form.charity, [prop]: value }
  },
  updateSelectedCharityDestination(state, payload) {
    console.log('[mutations] updateSelectedCharityDestination :' + state)
    const { form, select } = state
    if (payload) {
      state.selectedCharityDestination = payload
      form.charity.destination = []
      for (const item of payload) {
        form.charity.destination.push({ id: item.id, name: item.name })
      }
    } else {
      for (const item of form.charity.destination) {
        const destination = select.charityDestination.find(
          c => c.id === item.id
        )
        state.selectedCharityDestination.push(destination)
      }
    }
  },
}
