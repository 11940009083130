import cloneDeep from 'lodash-es/cloneDeep'
import {
  TEXT_FILTER,
  STATUS_FILTER,
  AGENCY_FILTER,
} from '@/constants/filterSearchType'

const initState = {
  search: '',
  items: [],
  agencies: [],
  statuses: [],
  filterType: {
    id: 'name',
    label: '名前',
  },
  filterItems: [
    {
      id: 'name.name',
      label: '名前',
      type: TEXT_FILTER,
    },
    {
      id: 'agencyText',
      label: '事務所',
      type: AGENCY_FILTER,
    },
    {
      id: 'sns',
      label: 'SNS種別',
      type: TEXT_FILTER,
    },
    {
      id: 'handle',
      label: 'ハンドルID',
      type: TEXT_FILTER,
    },
    {
      id: 'introducerId',
      label: '紹介者 ID',
    },
    {
      id: 'statusText',
      label: 'ステータス',
      type: STATUS_FILTER,
    },
  ],
  searchFilterItems: [],
  header: [
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
    },
    // {
    //   text: 'ID',
    //   value: 'id',
    //   sortable: true,
    // },
    {
      text: 'サムネイル',
      value: 'avatar',
      sortable: false,
    },
    {
      text: '名前',
      value: 'name.name',
      sortable: true,
    },
    {
      text: '事務所',
      value: 'agencyText',
      sortable: true,
    },
    {
      text: 'メールアドレス',
      value: 'emailAddress',
      sortable: false,
    },
    {
      text: '価格',
      value: 'price',
      sortable: true,
    },
    {
      text: 'SNS種別',
      value: 'sns',
      sortable: true,
    },
    {
      text: 'ハンドルID',
      value: 'handle',
      sortable: true,
    },
    {
      text: 'ステータス',
      value: 'statusText',
      sortable: false,
    },
    {
      text: '紹介者ID',
      value: 'introducerId',
      sortable: false,
    },
    {
      text: '作成日',
      value: 'createdAtText',
      sortable: true,
    },
    {
      text: '最終納品日',
      value: 'latestDeliveredAt',
      sortable: true,
    },
    {
      text: 'アプリインストール',
      value: 'appInstallText',
      sortable: false,
    },
    {
      text: '支払情報登録',
      value: 'paymentInfoText',
      sortable: false,
    },
  ],
}
export const defaultState = () => cloneDeep(initState)
