import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  menu: [],
  mini: false,
  scrollSettings: {
    maxScrollbarLength: 160,
  },
}

export const defaultState = () => cloneDeep(initState)
