<template>
  <div id="appRoot">
    <template>
      <v-app id="inspire" class="app">
        <app-drawer class="app--drawer" />
        <app-toolbar class="app--toolbar" />
        <v-content>
          <!-- Page Header -->
          <page-header />
          <div class="page-wrapper">
            <nuxt />
          </div>
          <!-- App Footer -->
          <v-footer height="auto" class="white pa-3 app--footer">
            <span class="caption">©{{ new Date().getFullYear() }} — CMerTV, Inc. All Rights Reserved.</span>
          </v-footer>
        </v-content>
      </v-app>
    </template>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="3000"
      :color="snackbar.color"
      bottom
      right
    >
      {{ snackbar.text }}
      <v-btn @click.native="snackbar.show = false" dark flat icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import AppDrawer from '@/components/AppDrawer'
import AppToolbar from '@/components/AppToolbar'
import PageHeader from '@/components/PageHeader'

export default {
  components: {
    AppDrawer,
    AppToolbar,
    PageHeader,
  },
  middleware: ['auth'],
  data: () => ({
    expanded: true,
    rightDrawer: false,
    snackbar: {
      show: false,
      text: '',
      color: '',
    },
  }),
}
</script>

<style lang="stylus" scoped>
.setting-fab
  top: 50% !important;
  right: 0;
  border-radius: 0

.page-wrapper
  min-height: calc(100vh - 64px - 50px - 81px);
  margin-bottom 50px;

.app--footer
  position absolute;
  bottom 0;
  width 100%;
</style>
