import { firebaseAction } from 'vuexfire'
import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG } from '@/modules/store/_core/ns'

const agencyRef = db.collection('agency')
const categoryRef = db.collection('category')
const introducerRef = db.collection('introducer')
const statusRef = db.collection('talent_status')
const charityDestinationRef = db.collection('charityDestination')
const talentRef = db.collection('talent')
const storageRef = firebase.storage().ref()

export const actions = {
  bindRef: firebaseAction(async ({ bindFirebaseRef }) => {
    console.log('[actions] BindRef :')
    await bindFirebaseRef('select.agencies', agencyRef)
    await bindFirebaseRef('select.categories', categoryRef)
    await bindFirebaseRef('select.introducers', introducerRef)
    await bindFirebaseRef('select.statuses', statusRef)
    await bindFirebaseRef('select.charityDestination', charityDestinationRef)
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    try {
      unbindFirebaseRef('select.agencies')
      unbindFirebaseRef('select.categories')
      unbindFirebaseRef('select.introducers')
      unbindFirebaseRef('select.statuses')
      unbindFirebaseRef('select.charityDestination')
    } catch (e) {
      // console.log('unBindRef error : ', e)
    }
  }),

  submit({ state, commit }) {
    console.log('[actions] submit :')
    const newTalent = db.collection('talent').doc()
    const path = 'avatar/' + newTalent.id + '/' + state.form.avatarInfo.name
    storageRef
      .child(path)
      .put(state.form.avatarInfo.file)
      .then(function(snapshot) {
        snapshot.ref.getDownloadURL().then(async function(downloadURL) {
          await talentRef
            .doc(newTalent.id)
            .set(makePost(state.form, downloadURL))
          commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
          commit('initializeState')
        })
      })
  },
}

function makePost(form, path) {
  const stamp = firebase.firestore.FieldValue.serverTimestamp()
  const {
    name,
    phonetic,
    message,
    price,
    emailAddress,
    sns,
    handle,
    termsAgree,
    introducerId,
    agencyId,
    category,
    status,
    isSns,
    isCharity,
    charity,
    level,
  } = form
  return {
    name: { name, phonetic },
    avatar: path,
    message,
    price,
    emailAddress,
    sns,
    handle,
    introducerId,
    agencyId,
    category,
    status,
    isSns: !!isSns,
    isCharity: !!isCharity,
    termsAgree: !!termsAgree,
    charity,
    // firestore用の現在時刻取得
    createdAt: stamp,
    level,
  }
}
