import { defaultState } from '@/modules/store/common/table-filter/_state'

const maxItems = 3
export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  deleteFilter(state, { item, index }) {
    console.log('[mutation] deleteFilter : ')
    state.items = state.items.filter((item, idx) => idx !== index)
    const hasNoFixedItem = state.items.find(item => !item.fixed)
    if (state.items.length === maxItems - 1 && !hasNoFixedItem) {
      state.items.push({
        id: new Date().getTime(),
        fixed: false,
        fixedType: undefined,
        fixedValue: undefined,
      })
    }
  },
  fixedFilter(state, { target, item }) {
    console.log('[mutation] fixedFilter : ', item)
    item.fixed = true
    item.fixedType = target.type
    item.fixedValue = target.value
    if (state.items.length < maxItems) {
      state.items.push({
        id: new Date().getTime(),
        fixed: false,
      })
    }
  },
}
