import { firebaseAction } from 'vuexfire'
import { db } from '@/plugins/firebase'
const talentRequestRef = db.collection('talent_request')

export const actions = {
  bindRef: firebaseAction(async ({ bindFirebaseRef, state, commit }) => {
    console.log('[actions] BindRef :')
    await bindFirebaseRef('items', talentRequestRef)
    commit('convertText')
  }),

  unBindRef: firebaseAction(({ unbindFirebaseRef, state }) => {
    try {
      console.log('[actions] unBindRef :')
      unbindFirebaseRef('talents')
      unbindFirebaseRef('items')
    } catch (e) {}
  }),

  convertText({ commit }) {
    commit('convertText')
  },
  changeStore({ commit, state }) {
    commit('convertText')
  },
  // note: Reviews, Offices情報が揃っていることが前提
  async submit({ dispatch, state }, payload) {
    console.log('[actions] submit :', payload)
    await talentRequestRef.doc(payload.id).update({ isActive: state.status })
    dispatch('changeStore')
  },
}
