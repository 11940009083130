import { convertDateToText, dateFileText } from '@/util/data_util'
import { exportCsvFile } from '@/util/file_util'
import { admin } from '@/constants/role'
import { defaultState } from '@/modules/store/talent/list/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  fetchItems(state, payload) {
    console.log('[mutations] fetchItems :')
    state.items = payload
  },
  updateFilterType(state, payload) {
    state.filterType = payload
  },
  updateSearchValue(state, payload) {
    state.search = payload
  },
  changeHeaderByRole(state, roleId) {
    if (admin !== roleId) {
      // 意図的に空配列を渡さないと、headerの読み込みでエラーになるのでsplice禁止
      delete state.header[3]
      delete state.header[9]
    }
  },

  convertText(state) {
    const { items, agencies, statuses, notifications } = state
    state.items = items.map(item => {
      const targetStatus = statuses.find(status => status.id === item.status)
      const targetAgency = agencies.find(agency => agency.id === item.agencyId)
      const notification = notifications.find(
        notification => notification.talentId === item.id
      )
      item.agencyText = targetAgency ? targetAgency.name : ''
      item.statusText = targetStatus ? targetStatus.name : ''
      item.createdAtText = item.createdAt
        ? convertDateToText(item.createdAt.toDate())
        : ''
      item.latestDeliveredAtText = item.latestDeliveredAt
        ? convertDateToText(item.latestDeliveredAt.toDate())
        : ''
      item.appInstallText = notification ? '〇' : '×'
      if (item.agencyId) {
        item.paymentInfoText = '事務所'
      } else if (item.accountId) {
        item.paymentInfoText = '〇'
      } else {
        item.paymentInfoText = '×'
      }
      return item
    })
  },

  exportCsv(state) {
    const headers = [
      '名前',
      'ID',
      '事務所',
      '価格',
      'SNS種別',
      'ハンドルID',
      'ステータス',
      '紹介者ID',
      '作成日',
      'アプリインストール',
      '支払情報登録',
    ]
    const records = state.items.map(item => {
      const {
        name,
        id,
        agencyText,
        price,
        sns,
        handle,
        statusText,
        introducerId,
        createdAtText,
        appInstallText,
        paymentInfoText,
      } = item
      return [
        name.name,
        id,
        agencyText,
        price,
        sns,
        handle,
        statusText,
        introducerId,
        createdAtText,
        appInstallText,
        paymentInfoText,
      ]
    })
    records.unshift(headers)
    const newRecords = records.map(record => {
      const newRecord = []
      for (let i = 0; i < record.length; i++) {
        if (typeof record[i] == 'string' && record[i].includes(',')) {
          const reRecord = '"' + record[i] + '"'
          newRecord.push(reRecord)
        } else {
          newRecord.push(record[i])
        }
      }
      return newRecord
    })
    const data = newRecords.map(record => record.join(',')).join('\r\n')
    const dateText = dateFileText()
    const fileName = 'Fanglee_talent' + dateText
    exportCsvFile(data, fileName)
  },

  updateFilter(state, payload) {
    console.log('[mutaiton] updateFilter : ', payload)
    state.searchFilterItems = payload
  },
}
