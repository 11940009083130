import { defaultState } from './_state'
import { agency } from '@/constants/role'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  changeFormValue(state, payload) {
    console.log('[mutations] changeFormValue :', payload)
    const { prop, value } = payload
    state.form = { ...state.form, [prop]: value }
  },
  updateAgencies(state, payload) {
    console.log('[mutations] updateOffices :')
    state.select.agencies = payload
  },
  updateRoles(state, payload) {
    console.log('[mutations] updateRoles :')
    state.select.roles = payload
  },
  updateSelectedRole(state, payload) {
    console.log('[mutations] updateSelectedRole :', payload)
    const { select, form } = state
    const roleId = payload ? payload.id : form.roleId
    state.selectedRole = select.roles.find(r => r.id === roleId)
    form.roleId = state.selectedRole ? state.selectedRole.id : null
    state.isAgency = form.roleId === agency
  },
  updateSelectedAgency(state, payload) {
    console.log('[mutations] updateSelectedAgency :', payload)
    const { select, form } = state
    const agencyId = payload ? payload.id : form.agencyId
    state.selectedAgency = select.agencies.find(a => a.id === agencyId)
    form.agencyId = state.selectedAgency ? state.selectedAgency.id : null
  },
}
