import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG, NS_ERROR_DIALOG } from '@/modules/store/_core/ns'

const introducerRef = db.collection('introducer')

export const actions = {
  submit({ commit, state }) {
    introducerRef
      .add(makePost(state.form))
      .then(() => {
        commit('initializeState')
        commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
      })
      .catch(() => {
        commit(
          `${NS_ERROR_DIALOG}/showDialog`,
          '事務所の新規追加に失敗しました。',
          {
            root: true,
          }
        )
      })
  },
}

function makePost(form) {
  const post = {}
  const stamp = firebase.firestore.FieldValue.serverTimestamp()
  post.name = form.name
  post.bank = form.bank
  post.margin = form.margin
  post.expire = form.expire
  post.phoneNumber = form.phoneNumber
  post.emailAddress = form.emailAddress
  // firestore用の現在時刻取得
  post.createdAt = stamp
  post.updatedAt = stamp
  return post
}
