import { convertDateToText } from '@/util/data_util'
import { defaultState } from '@/modules/store/agency/list/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  setIdForRemove(state, payload) {
    state.removeId = payload
  },
  updateFilterType(state, payload) {
    state.filterType = payload
  },
  updateSearchValue(state, payload) {
    state.search = payload
  },
  convertText(state) {
    const { items } = state
    state.items = items.map(item => {
      // ここでエラーが走る
      item.createdAtText = item.createdAt
        ? convertDateToText(item.createdAt.toDate())
        : ''
      item.updatedAtText = item.updatedAt
        ? convertDateToText(item.updatedAt.toDate())
        : ''
      return item
    })
  },
}
