import cloneDeep from 'lodash-es/cloneDeep'
import { TEXT_FILTER } from '@/constants/filterSearchType'

const initState = {
  search: '',
  items: [],
  talentRequests: [],
  offers: [],
  talents: [],
  status: false,
  filterType: {
    id: 'name',
    label: 'タレント名',
  },
  filterItems: [
    {
      id: 'name',
      label: 'タレント名',
      type: TEXT_FILTER,
    },
  ],
  searchFilterItems: [],
  header: [
    {
      text: 'タレント名',
      value: 'name',
      sortable: true,
    },
    {
      text: 'リクエスト内容',
      value: 'content',
      sortable: false,
    },
    {
      text: '作成日時',
      value: 'createdAtText',
      sortable: true,
    },
  ],
}

export const defaultState = () => cloneDeep(initState)
