import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b0728b66 = () => interopDefault(import('../pages/agency/index.vue' /* webpackChunkName: "pages/agency/index" */))
const _23b20a34 = () => interopDefault(import('../pages/charity/index.vue' /* webpackChunkName: "pages/charity/index" */))
const _569d47bc = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _671e197b = () => interopDefault(import('../pages/introducer/index.vue' /* webpackChunkName: "pages/introducer/index" */))
const _38489cb4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _51bdb92c = () => interopDefault(import('../pages/offer/index.vue' /* webpackChunkName: "pages/offer/index" */))
const _01017afe = () => interopDefault(import('../pages/office.vue' /* webpackChunkName: "pages/office" */))
const _2b84c9e0 = () => interopDefault(import('../pages/review/index.vue' /* webpackChunkName: "pages/review/index" */))
const _fdd4cb18 = () => interopDefault(import('../pages/talent/index.vue' /* webpackChunkName: "pages/talent/index" */))
const _457181d3 = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _78c4da11 = () => interopDefault(import('../pages/agency/create.vue' /* webpackChunkName: "pages/agency/create" */))
const _0326a6d0 = () => interopDefault(import('../pages/charity/create.vue' /* webpackChunkName: "pages/charity/create" */))
const _b74d3eba = () => interopDefault(import('../pages/introducer/create.vue' /* webpackChunkName: "pages/introducer/create" */))
const _6d5c026c = () => interopDefault(import('../pages/talent/create.vue' /* webpackChunkName: "pages/talent/create" */))
const _df19f96a = () => interopDefault(import('../pages/user/create.vue' /* webpackChunkName: "pages/user/create" */))
const _09bafc76 = () => interopDefault(import('../pages/web/news/index.vue' /* webpackChunkName: "pages/web/news/index" */))
const _6eef4adf = () => interopDefault(import('../pages/web/pickup/index.vue' /* webpackChunkName: "pages/web/pickup/index" */))
const _5558dd08 = () => interopDefault(import('../pages/web/news/create.vue' /* webpackChunkName: "pages/web/news/create" */))
const _c4acb744 = () => interopDefault(import('../pages/web/news/_id.vue' /* webpackChunkName: "pages/web/news/_id" */))
const _6e68ebf5 = () => interopDefault(import('../pages/agency/_id.vue' /* webpackChunkName: "pages/agency/_id" */))
const _6ce27ece = () => interopDefault(import('../pages/charity/_id.vue' /* webpackChunkName: "pages/charity/_id" */))
const _40ca3ea3 = () => interopDefault(import('../pages/introducer/_id.vue' /* webpackChunkName: "pages/introducer/_id" */))
const _7894f852 = () => interopDefault(import('../pages/offer/_id.vue' /* webpackChunkName: "pages/offer/_id" */))
const _2e6fa9dc = () => interopDefault(import('../pages/talent/_id.vue' /* webpackChunkName: "pages/talent/_id" */))
const _290bdcfb = () => interopDefault(import('../pages/user/_id.vue' /* webpackChunkName: "pages/user/_id" */))
const _6ea0f99d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agency",
    component: _b0728b66,
    name: "agency"
  }, {
    path: "/charity",
    component: _23b20a34,
    name: "charity"
  }, {
    path: "/dashboard",
    component: _569d47bc,
    name: "dashboard"
  }, {
    path: "/introducer",
    component: _671e197b,
    name: "introducer"
  }, {
    path: "/login",
    component: _38489cb4,
    name: "login"
  }, {
    path: "/offer",
    component: _51bdb92c,
    name: "offer"
  }, {
    path: "/office",
    component: _01017afe,
    name: "office"
  }, {
    path: "/review",
    component: _2b84c9e0,
    name: "review"
  }, {
    path: "/talent",
    component: _fdd4cb18,
    name: "talent"
  }, {
    path: "/user",
    component: _457181d3,
    name: "user"
  }, {
    path: "/agency/create",
    component: _78c4da11,
    name: "agency-create"
  }, {
    path: "/charity/create",
    component: _0326a6d0,
    name: "charity-create"
  }, {
    path: "/introducer/create",
    component: _b74d3eba,
    name: "introducer-create"
  }, {
    path: "/talent/create",
    component: _6d5c026c,
    name: "talent-create"
  }, {
    path: "/user/create",
    component: _df19f96a,
    name: "user-create"
  }, {
    path: "/web/news",
    component: _09bafc76,
    name: "web-news"
  }, {
    path: "/web/pickup",
    component: _6eef4adf,
    name: "web-pickup"
  }, {
    path: "/web/news/create",
    component: _5558dd08,
    name: "web-news-create"
  }, {
    path: "/web/news/:id",
    component: _c4acb744,
    name: "web-news-id"
  }, {
    path: "/agency/:id",
    component: _6e68ebf5,
    name: "agency-id"
  }, {
    path: "/charity/:id",
    component: _6ce27ece,
    name: "charity-id"
  }, {
    path: "/introducer/:id",
    component: _40ca3ea3,
    name: "introducer-id"
  }, {
    path: "/offer/:id",
    component: _7894f852,
    name: "offer-id"
  }, {
    path: "/talent/:id",
    component: _2e6fa9dc,
    name: "talent-id"
  }, {
    path: "/user/:id",
    component: _290bdcfb,
    name: "user-id"
  }, {
    path: "/",
    component: _6ea0f99d,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
