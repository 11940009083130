import format from 'date-fns/format'

export function convertDateToText(date) {
  return format(date, 'YYYY/MM/DD HH:mm:ss')
}
export function calendarText(date) {
  return format(date, 'YYYY-MM-DD')
}
export function dateFileText() {
  const nowDate = new Date()
  const year = nowDate.getFullYear()
  const mon = nowDate.getMonth() + 1
  const day = nowDate.getDate()
  const hour = nowDate.getHours()
  const min = nowDate.getMinutes()
  const sec = nowDate.getSeconds()
  return '_' + year + mon + day + hour + min + sec
}
