import { db, firebase } from '@/plugins/firebase'
import { NS_COMPLETE_DIALOG } from '@/modules/store/_core/ns'

const charityRef = db.collection('charityDestination')
const storageRef = firebase.storage().ref()

export const actions = {
  submit({ commit, state }) {
    const newCharity = db.collection('charityDestination').doc()
    const path = 'logo/' + newCharity.id + '/' + state.form.logo.name

    storageRef
      .child(path)
      .put(state.form.logo.file)
      .then(function(snapshot) {
        snapshot.ref.getDownloadURL().then(async function(downloadURL) {
          await charityRef
            .doc(newCharity.id)
            .set(makePost(state.form, downloadURL))
          commit(`${NS_COMPLETE_DIALOG}/showDialog`, null, { root: true })
          commit('initializeState')
        })
      })
  },
}

function makePost(form, path) {
  const post = {}
  const stamp = firebase.firestore.FieldValue.serverTimestamp()
  post.name = form.name
  post.link = form.link
  post.logo = path
  post.memo = form.memo
  // firestore用の現在時刻取得
  post.createdAt = stamp
  post.updatedAt = stamp
  return post
}
