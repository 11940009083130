import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  form: {
    title: '',
    content: null,
    isNotice: false,
  },
}

export const defaultState = () => cloneDeep(initState)
