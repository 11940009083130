export function exportCsvFile(csvTest, fileName) {
  // eslint-disable-next-line prettier/prettier
  const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
  const blob = new Blob([bom, csvTest], { type: 'text/csv' })
  const url = (window.URL || window.webkitURL).createObjectURL(blob)
  const link = document.createElement('a')
  link.download = `${fileName}.csv`
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
