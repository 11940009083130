import { defaultState } from '@/modules/store/common/logIn/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  changeFormValue(state, payload) {
    console.log('[mutations] changeFormValue :', payload)
    const { prop, value } = payload
    state.form = { ...state.form, [prop]: value }
  },
  success() {
    console.log('[mutations] success :')
    this.$router.push('/dashboard')
  },
  onAuthStateChanged(state, user) {
    console.log('[mutations] onAuthStateChanged :', Date.now())
    state.user = user
  },
  onUserStatusChanged(state, status) {
    state.status = status
  },
}
