export const getters = {
  form(state) {
    return state.form
  },
  convertItems(state) {
    return state.convertItems
  },
  select(state) {
    return state.select
  },
  selectedCategories(state) {
    return state.selectedCategories
  },
  selectedAgency(state) {
    return state.selectedAgency
  },
  selectedIntroducer(state) {
    return state.selectedIntroducer
  },
  selectedStatus(state) {
    return state.selectedStatus
  },
  noAgency(state) {
    return state.form.agencyId === ''
  },
  noBankNumber(state) {
    return (
      !state.form.bank.hasOwnProperty('bankNumber') ||
      (state.form.bank.hasOwnProperty('bankNumber') &&
        state.form.bank.bankNumber === '')
    )
  },
  avatarPath(state) {
    const { form } = state
    if (form.avatar) {
      return form.avatar
    } else if (form.avatarInfo.image) {
      return form.avatarInfo.image
    } else {
      return ''
    }
  },
  selectedCharityDestination(state) {
    return state.selectedCharityDestination
  },
}
