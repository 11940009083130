import { defaultState } from '@/modules/store/common/error-dialog/_state'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  showDialog(state, payload) {
    console.log('[mutations] showDialog :')
    state.visible = true
    state.message = payload
  },
  close(state) {
    console.log('[mutations] close :')
    state.visible = false
  },
}
