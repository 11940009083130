<template>
  <v-layout row class="align-center layout px-4 pt-4 app--page-header">
    <div class="page-header-left">
      <h3 class="pr-3">
        {{ title }} 管理
      </h3>
    </div>
    <v-icon>home</v-icon>
    <v-breadcrumbs :items="breadcrumbs" divider="-" />
    <v-spacer />
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { NS_APP_DRAWER } from '@/modules/store/_core/ns'

export default {
  data() {
    return {
      title: '',
    }
  },
  computed: {
    ...mapGetters(NS_APP_DRAWER, ['menu']),
    breadcrumbs() {
      const breadcrumbs = []
      this.menu.forEach(item => {
        if (item.items) {
          const child = item.items.find(i => {
            return i.href === this.$route.path
          })
          if (child) {
            breadcrumbs.push({ text: item.title })
            breadcrumbs.push({ text: child.title, disabled: true })
            this.setTitle(child.title)
          }
        } else if (item.href === this.$route.path) {
          this.setTitle(item.title)
          breadcrumbs.push({ text: item.title })
        }
      })
      return breadcrumbs
    },
  },
  methods: {
    setTitle(data) {
      this.title = data
    },
  },
}
</script>
