import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  form: {
    name: '',
    link: '',
    logo: '',
    logoInfo: {
      file: '',
      name: '',
      image: '',
    },
    memo: '',
  },
  remote: {},
}

export const defaultState = () => cloneDeep(initState)
