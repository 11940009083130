import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  search: '',
  items: [],
  filterType: {
    id: 'name',
    label: '団体名',
  },
  filterItems: [
    {
      id: 'name',
      label: '団体名',
    },
  ],
  header: [
    {
      text: '操作',
      width: '50px',
      align: 'centor',
      value: 'actions',
      sortable: false,
    },
    {
      text: '団体名',
      value: 'name',
      sortable: true,
    },
    {
      text: 'メモ',
      value: 'memo',
      sortable: true,
    },
    {
      text: '作成日時',
      value: 'createdAtText',
      sortable: true,
    },
    {
      text: '更新日時',
      value: 'updatedAtText',
      sortable: true,
    },
  ],
  removeId: '',
}

export const defaultState = () => cloneDeep(initState)
