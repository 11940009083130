import cloneDeep from 'lodash-es/cloneDeep'
import sum from 'lodash-es/sum'
import { defaultState } from '@/modules/store/dashboard/_state'
import {
  talentApplication,
  talentApproval,
  listingApplication,
  accepting,
  stopped,
  unsubscribe,
} from '@/constants/talentStatus'
import {
  offerNotCompatible,
  offerRejected,
  offerExpired,
  offerCompleted,
  offerPaid,
  offerCancel,
} from '@/constants/offerStatus'
import { roundAt, formatCount, formatCurrency } from '@/util/formatter_util'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  createGraph(state) {
    const { talents, offers } = state
    const t = cloneDeep(talents)
    const t1 = t.filter(t => t.status === talentApplication).length
    const t2 = t.filter(t => t.status === talentApproval).length
    const t3 = t.filter(t => t.status === listingApplication).length
    const t4 = t.filter(t => t.status === accepting).length
    const t5 = t.filter(t => t.status === stopped).length
    const t6 = t.filter(t => t.status === unsubscribe).length * 3
    state.talentAllPeriod = [t1, t2, t3, t4, t5, t6].reverse()

    const o = cloneDeep(offers)
    const o1 = o.filter(o => o.status === offerNotCompatible).length
    const o2 = o.filter(o => o.status === offerRejected).length
    const o3 = o.filter(o => o.status === offerExpired).length
    const o4 = o.filter(o => o.status === offerCompleted).length
    const o5 = o.filter(o => o.status === offerPaid).length
    const o6 = o.filter(o => o.status === offerCancel).length
    state.offerAllPeriod = [o1, o2, o3, o4, o5, o6].reverse()
  },
  changeDateRange(state) {
    const { offers, offerFromDate, offerToDate, offerByPeriod } = state
    const byPeriodCreatedAtOffer = offers.filter(o => {
      if (o.createdAt && typeof o.createdAt === 'object') {
        const time = new Date(o.createdAt.toDate()).getTime()
        const fromDate = new Date(offerFromDate).getTime()
        const toDate = new Date(offerToDate).getTime()
        return fromDate <= time && time <= toDate
      }
      return false
    })

    const o = cloneDeep(byPeriodCreatedAtOffer)
    const ot = o.length
    const o1 = o.filter(o => o.status === offerNotCompatible).length
    const o2 = o.filter(o => o.status === offerRejected).length
    const o3 = o.filter(o => o.status === offerExpired).length
    const o4 = o.filter(o => o.status === offerCompleted).length
    const o5 = o.filter(o => o.status === offerPaid).length
    const o6 = o.filter(o => o.status === offerCancel).length

    const op1 = roundAt((o1 / ot) * 100, 2)
    const op2 = roundAt((o2 / ot) * 100, 2)
    const op3 = roundAt((o3 / ot) * 100, 2)
    const op4 = roundAt((o4 / ot) * 100, 2)
    const op5 = roundAt((o5 / ot) * 100, 2)
    const op6 = roundAt((o6 / ot) * 100, 2)

    offerByPeriod[0].headline = `${o1}件`
    offerByPeriod[1].headline = `${o2}件`
    offerByPeriod[2].headline = `${o3}件`
    offerByPeriod[3].headline = `${o4}件`
    offerByPeriod[4].headline = `${o5}件`
    offerByPeriod[5].headline = `${o6}件`

    offerByPeriod[0].value = op1 || 0
    offerByPeriod[1].value = op2 || 0
    offerByPeriod[2].value = op3 || 0
    offerByPeriod[3].value = op4 || 0
    offerByPeriod[4].value = op5 || 0
    offerByPeriod[5].value = op6 || 0

    // オファー数 ( 依頼日 (createdAtText) がフィルタ期間内のオファーの数 )
    const byPeriodCreatedAtOfferTotalNumber = formatCount(
      byPeriodCreatedAtOffer.length
    )

    // オファー金額 ( 依頼日 (createdAtText) がフィルタ期間内のオファーの金額の合計 )
    const byPeriodCreatedAtOfferTotalPrice = formatCurrency(
      sum(byPeriodCreatedAtOffer.map(o => Number(o.price) || 0))
    )

    const byPeriodPaidAtOffer = o.filter(o => {
      if (o.paidAt && typeof o.paidAt === 'object') {
        const time = new Date(o.paidAt.toDate()).getTime()
        const fromDate = new Date(offerFromDate).getTime()
        const toDate = new Date(offerToDate).getTime()
        return fromDate <= time && time <= toDate
      }
      return false
    })

    // 決済オファー数 ( 決済日 (paidAtText) がフィルタ期間内のオファーの数 )
    const byPeriodPaidAtOfferTotalNumber = formatCount(
      byPeriodPaidAtOffer.length
    )
    // 売上 ( 決済日 (paidAtText) がフィルタ期間内の金額の合計 )
    const byPeriodPaidAtOfferTotalPrice = formatCurrency(
      sum(byPeriodPaidAtOffer.map(o => Number(o.price) || 0))
    )
    state.byPeriodCreatedAtOfferTotalNumber = byPeriodCreatedAtOfferTotalNumber
    state.byPeriodCreatedAtOfferTotalPrice = byPeriodCreatedAtOfferTotalPrice
    state.byPeriodPaidAtOfferTotalNumber = byPeriodPaidAtOfferTotalNumber
    state.byPeriodPaidAtOfferTotalPrice = byPeriodPaidAtOfferTotalPrice
  },
  setOfferFromDate(state, date) {
    state.offerFromDate = date
  },
  setOfferToDate(state, date) {
    state.offerToDate = date
  },
}
