import { firebaseMutations } from 'vuexfire'

import { commonCompleteDialogStore } from '../modules/store/common/complete-dialog/store'
import { commonConfirmDialogStore } from '../modules/store/common/confirm-dialog/store'
import { commonTableFilterStore } from '../modules/store/common/table-filter/store'
import { commonErrorDialogStore } from '../modules/store/common/error-dialog/store'
import { logInStore } from '../modules/store/common/logIn/store'
import { offerEditStore } from '../modules/store/offer/edit/store'
import { offerListStore } from '../modules/store/offer/list/store'
import { talentEditStore } from '../modules/store/talent/edit/store'
import { talentListStore } from '../modules/store/talent/list/store'
import { talentCreateStore } from '../modules/store/talent/create/store'
import { userEditStore } from '../modules/store/user/edit/store'
import { userListStore } from '../modules/store/user/list/store'
import { userCreateStore } from '../modules/store/user/create/store'
import { agencyEditStore } from '../modules/store/agency/edit/store'
import { agencyListStore } from '../modules/store/agency/list/store'
import { agencyCreateStore } from '../modules/store/agency/create/store'
import { reviewListStore } from '../modules/store/review/list/store'
import { AppDrawerStore } from '../modules/store/common/AppDrawer/store'
import { introducerEditStore } from '../modules/store/introducer/edit/store'
import { introducerListStore } from '../modules/store/introducer/list/store'
import { introducerCreateStore } from '../modules/store/introducer/create/store'
import { charityEditStore } from '../modules/store/charity/edit/store'
import { charityListStore } from '../modules/store/charity/list/store'
import { charityCreateStore } from '../modules/store/charity/create/store'

import { talentRequestListStore } from '../modules/store/talent_request/list/store'

import { webStore } from '../modules/store/web/store'
import { webNewsEditStore } from '../modules/store/web/news/edit/store'
import { webNewsListStore } from '../modules/store/web/news/list/store'
import { webNewsCreateStore } from '../modules/store/web/news/create/store'
import { dashboardStore } from '@/modules/store/dashboard/store'

export const state = () => ({
  drawer: true,
})
export const strict = false
export const mutations = {
  toggleDrawer(state) {
    state.drawer = !state.drawer
  },
  drawer(state, val) {
    state.drawer = val
  },
  ...firebaseMutations,
}

export const modules = {
  completeDialog: commonCompleteDialogStore,
  confirmDialog: commonConfirmDialogStore,
  tableFilter: commonTableFilterStore,
  errorDialog: commonErrorDialogStore,
  dashboard: dashboardStore,
  offerEdit: offerEditStore,
  offerList: offerListStore,
  logIn: logInStore,
  talentEdit: talentEditStore,
  talentList: talentListStore,
  talentCreate: talentCreateStore,
  userEdit: userEditStore,
  userList: userListStore,
  userCreate: userCreateStore,
  agencyEdit: agencyEditStore,
  agencyList: agencyListStore,
  agencyCreate: agencyCreateStore,
  reviewList: reviewListStore,
  AppDrawer: AppDrawerStore,
  introducerEdit: introducerEditStore,
  introducerList: introducerListStore,
  introducerCreate: introducerCreateStore,
  web: webStore,
  webNewsEdit: webNewsEditStore,
  webNewsList: webNewsListStore,
  webNewsCreate: webNewsCreateStore,
  charityEdit: charityEditStore,
  charityList: charityListStore,
  charityCreate: charityCreateStore,
  talentRequestList: talentRequestListStore,
}
