const talentApplication = '0' // タレント申請
const talentApproval = '1' // タレント承認
const listingApplication = '2' // 出品申請
const accepting = '3' // 受付中
const stopped = '4' // 停止中
const unsubscribe = '5' // 退会

export {
  talentApplication,
  talentApproval,
  listingApplication,
  accepting,
  stopped,
  unsubscribe,
}
