export const getters = {
  form(state) {
    return state.form
  },
  select(state) {
    return state.select
  },
  selectedRole(state) {
    return state.selectedRole
  },
  selectedAgency(state) {
    return state.selectedAgency
  },
  isAgency(state) {
    return state.isAgency
  },
}
