import { defaultState } from './_state'
import { convertDateToText } from '@/util/data_util'

export const mutations = {
  initializeState(state) {
    Object.assign(state, defaultState())
  },
  convertText(state) {
    const { newsList } = state
    state.newsList = newsList.map(item => {
      item.createdAtText = item.createdAt
        ? convertDateToText(item.createdAt.toDate())
        : ''
      item.updatedAtText = item.updatedAt
        ? convertDateToText(item.updatedAt.toDate())
        : ''
      return item
    })
  },
}
