import cloneDeep from 'lodash-es/cloneDeep'

const initState = {
  items: [
    {
      id: new Date().getTime(),
      fixed: false,
      fixedType: undefined,
      fixedValue: undefined,
    },
  ],
}

export const defaultState = () => cloneDeep(initState)
