import {
  AGENCY_FILTER,
  STATUS_FILTER,
  TEXT_FILTER,
  ROLE_FILTER,
  DATE_FILTER,
} from '@/constants/filterSearchType'

export function filterTableItems(state) {
  const { items, searchFilterItems } = state
  if (!searchFilterItems || searchFilterItems.length === 0) {
    return items
  }
  const fixedConditions = searchFilterItems.filter(item => item.fixed)

  if (fixedConditions.length === 0) {
    return items
  } else {
    return items.filter(item => {
      return fixedConditions.every(c => {
        const { fixedType, fixedValue } = c
        let v
        if (fixedType.type === TEXT_FILTER) {
          // note: タレント「名前」の独自仕様
          if (fixedType.id === 'name.name') {
            v = item.name.name
          } else {
            v = item[fixedType.id]
          }
          const s = fixedValue ? fixedValue[0].toLowerCase() : ''
          return v ? v.toLowerCase().includes(s) : false
        } else if (
          fixedType.type === STATUS_FILTER ||
          fixedType.type === AGENCY_FILTER ||
          fixedType.type === ROLE_FILTER
        ) {
          v = item[fixedType.id]
          return v === fixedValue[0]
        } else if (fixedType.type === DATE_FILTER) {
          const from = fixedValue[0]
          const to = fixedValue[1]
          const fromDate = from ? new Date(from).getTime() : from
          const toDate = to ? new Date(to).getTime() : to
          v = new Date(item[fixedType.id]).getTime()
          if (fromDate && toDate) {
            return fromDate <= v && v <= toDate
          } else if (!fromDate) {
            return v <= toDate
          } else {
            return fromDate <= v
          }
        } else {
          return false
        }
      })
    })
  }
}
